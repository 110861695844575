var user = null;
try {
	user = JSON.parse(localStorage.getItem('user'));
} catch (error) {
	//
}
const initialState = user ?  { user } : { user : { _id : "" } } ;

export const AuthConstant = {
	UPDATED : "AUTH_UPDATED",
	LOGOUT : "AUTH_LOGOUT",
	UPDATEUSER : "AUTH_UPDATEUSER",
	LOGIN_SUCCESS : "AUTH_LOGIN_SUCCESS",
	LOGIN_FAIL : "AUTH_LOGIN_FAIL",
};

export const AuthReducer = (state = initialState, action) => {
	switch (action.type) {
		case AuthConstant.LOGIN_SUCCESS:
			return {...state, logged : true, loginresult: true, user : action.user };
		case AuthConstant.UPDATEUSER:
			localStorage.setItem('user', JSON.stringify({... state.user, ...action.user}));
			return {...state, user : {... state.user, ...action.user} };
		case AuthConstant.LOGIN_FAIL:
			return { loginresult: true, logged : false };
		case AuthConstant.UPDATED:
			return { ...state, user: action.user };
		case AuthConstant.LOGOUT:
			return { user : { _id : "" } };
		default:
			return state;
	}
};
