import { Store } from '../store'
import { history } from '../history';

export const RTSize = ({ bytes, decimals = 2 }) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return <>{parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} {sizes[i]}</>
}

export const nFormat = (s) => {
    return s.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const RTGroup = ({ gid, readonly }) => {
	const grp = Store.getState().ProgramReducer.allgroups.find(x => x._id === gid);
    return readonly ? <span>{grp?.name}</span> : <a onClick={() => history.push("/group/" + gid)} className="cursor-pointer">{grp?.name}</a>;
}

export const RTPartner = ({ pid, readonly }) => {
	const partner = Store.getState().ProgramReducer.allpartner.find(x => x._id === pid);
    return readonly ? <span>{partner?.name}</span> : <a onClick={() => history.push("/partner/" + pid)} className="cursor-pointer">{partner?.name}</a>;
}
export const XName = (un) => {
    return un;
    if(!un)
        return "";
    const ln = Math.round(un.length / 2);
    console.log(ln);
    let oo = "";
    for(var n = 0; n < ln; n++){ oo += "O" }
    return oo + un.substr(ln);
}
export const XEmail = (un) => {
    return un;
    if(!un)
        return "";
    const id = un.split("@")[0];
    const dom = un.split("@")[1];
    const ln = Math.round(id.length / 2);
    console.log(ln);
    let oo = "";
    for(var n = 0; n < ln; n++){ oo += "?" }
    return oo + id.substr(ln) + "@" + dom;
}
