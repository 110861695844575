import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation, useParams } from 'react-router-dom';
import { ProgramConstant } from '../redux/reducers';
import { COUNTRIES } from '../config/country';
import { FaUser, FaStore, FaSave } from 'react-icons/fa';
import User from '../components/user';
import { API } from '../config/api';
import { RTButton } from '../libs/button';
import RTTable from '../libs/table';
import { RTGroup } from '../libs/string';
import { idData, fetchData } from '../libs/db';
import { Store } from '../store'

const CompGroupUserList = ({ user, title, group, allusers }) => {

	const dispatch = useDispatch();

	const [t] = useTranslation(['translation']);
	const [ items, setItems ] = useState([]);
	const [ process, setProcess ] = useState(false);

	useEffect(() => {
		getitems();
	},[group]);

	const getitems = async () => {
		setItems(allusers.filter(x => x.groupId === group._id));
	}

	return <div className="card mb-5 shadow">
		<div className="card-header">
			<div className="card-title">{title}</div>
			<div className="card-toolbar"></div>
		</div>
		<div className="card-body py-4">
			<table className="table align-middle table-row-bordered mb-0 fs-6 min-w-300px">
				<tbody className="fw-bold text-gray-600">
					{items.sort((a, b) => a.updated > b.updated ? -1 : 1).map((x, idx) => <tr className="" key={"srt-" + idx}>
						<td>
							<User _id={x._id} region={x.region} />
						</td>
						<td className="text-muted text-end">
							<div className="badge badge-secondary me-2">{moment(x.updated).format("L")}</div>
						</td>
					</tr>)}
				</tbody>
			</table>
		</div>
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const allusers =  state.ProgramReducer.allusers;

	return { user, allusers };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(CompGroupUserList);
