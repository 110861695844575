import { Component } from "react";
import { Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";

export const AuthRoute = ({ loginresult, component: Component, roles, ...rest}) => {
    return (
        <Route
			{...rest}
			render={(props) => {
				if (!loginresult) {
					return (
						<Redirect
							to={{
								pathname: "/Login",
								state: { from: props.location },
							}}
						/>
					);
				}else if(Component){
					return <Component {...props} />;
				}else{
					return <Redirect
						to={{
							pathname: "/HomePage",
							state: { from: props.location },
						}}
					/>
				}
			}}
		/>

        
    )
};

const mapState = (state) => {
	const user = state.AuthReducer.user;
	const loginresult = state.AuthReducer.loginresult;
	
	return { user, loginresult };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(AuthRoute);
