import { COUNTRIES } from '../config/country';
import Select from 'react-select';
import { useEffect, useState } from 'react';
import { Store } from '../store';

export const RTCountrySelect = ({ options = {}, handleChanged, readonly = false, css = "" }) => {

	const [focus, setFocus] = useState(false);
	const [val, setVal] = useState("");
    const domain = window.location.href.indexOf(".rayteams.com") > -1 ? "rayteams.com" : "raydevelop.com";
	const list = domain === "rayteams.com" ? 
        COUNTRIES.map(x => ({ value : x.countryCode, label : x.name })) :
        COUNTRIES.filter(x => x.countryCode === "KR").map(x => ({ value : x.countryCode, label : x.name }));

	useEffect(() => {
		setVal(list.find(x => x.value === options.value) || '');
	}, [options.value])

	const onChanged = (e) =>{
		setVal(e);
		handleChanged && handleChanged({ target : { name : options.name, value : e.value }});
	} 

	return <div className={"" + 
			(css && " " + css) +
			(focus ? " focused" : "") +
			(options.err ? " has-error" : "") }>
		{(!options.readonly && !readonly) && <>
			{options.label && <label className={options.required ? 'form-label required' : 'form-label'}>{options.label}<span/></label>}
			<Select 
				size={"sm"}
				name={options.name} 
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}
				value={val || ''} 
				className="text-dark"
				options={ domain === "rayteams.com" ? 
                    COUNTRIES.map(x => ({ value : x.countryCode, label : x.name })) :
                    COUNTRIES.filter(x => x.countryCode === "KR").map(x => ({ value : x.countryCode, label : x.name }))
                }
				styles={options?.customStyles}
				placeholder={options?.placeholder ?? 'Select...'}
				onChange={onChanged} />
			{options.err && <label className="error" for={options.name}>{options.errmsg}</label>} 
			{options.hint && <label className="help" for={options.name}>{options.hint}</label>} 
		</>}
		{(options.readonly || readonly) && <>
			<label className='form-label'>{options.label || upperFistChar(options.name)}<span/></label>
			{getCheckNullView(options, val ? val.label : "")}
		</>}
	</div>
}

export const RTPartnerSelect = ({ options = {}, handleChanged, readonly = false, css = "" }) => {

	const [focus, setFocus] = useState(false);
	const [val, setVal] = useState({});
	const list = [...Store.getState().ProgramReducer.allpartner.map(x => ({ value : x._id, label : x.name })), { value : "fdsfas", label : "FDSfdsafsd"}];

	useEffect(() => {
		setVal(list.find(x => x.value === options.value) || '');
	}, [options.value])

	const onChanged = (e) =>{
		setVal(e);
		handleChanged && handleChanged({ target : { name : options.name, value : e.value }});
	} 

	return <div className={"" + 
			(css && " " + css) +
			(focus ? " focused" : "") +
			(options.err ? " has-error" : "") }>
		{(!options.readonly && !readonly) && <>
			{options.label && <label className={options.required ? 'form-label required' : 'form-label'}>{options.label}<span/></label>}
			<Select 
				size={"sm"}
				name={options.name} 
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}
				value={val || ''} 
				className="text-dark"
				options={list}
				styles={options?.customStyles}
				placeholder={options?.placeholder ?? 'Select...'}
				onChange={onChanged} />
			{options.err && <label className="error" for={options.name}>{options.errmsg}</label>} 
			{options.hint && <label className="help" for={options.name}>{options.hint}</label>} 
		</>}
		{(options.readonly || readonly) && <>
			<label className='form-label'>{options.label || upperFistChar(options.name)}<span/></label>
			{getCheckNullView(options, val ? val.label : "")}
		</>}
	</div>
}

export const RTSelect = ({ options = {}, handleChanged, readonly = false, css = "" }) => {

	const [focus, setFocus] = useState(false);
	const [val, setVal] = useState("");
	const [list, setList] = useState([]);

	useEffect(() => {
		setList(options.list);
	}, [options.list])

	useEffect(() => {
		setVal(list.find(x => x.value === options.value) || '');
	}, [options.value, list])

	const onChanged = (e) =>{
		setVal(e.value);
		handleChanged && handleChanged({ target : { name : options.name, value : e.value }});
	} 

	return <div className={"" + 
			(css && " " + css) +
			(focus ? " focused" : "") +
			(options.err ? " has-error" : "") }>
		{(!options.readonly && !readonly) && <>
			{options.label && <label className={options.required ? 'form-label required' : 'form-label'}>{options.label}<span/></label>}
			<Select 
				size={"sm"}
				name={options.name} 
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}
				value={val || ''} 
				className="text-dark"
				options={list}
				styles={options?.customStyles}
				placeholder={options?.placeholder ?? 'Select...'}
				onChange={onChanged} />
			{options.err && <label className="error" for={options.name}>{options.errmsg}</label>} 
			{options.hint && <label className="help" for={options.name}>{options.hint}</label>} 
		</>}
		{(options.readonly || readonly) && <>
			<label className='form-label'>{options.label || upperFistChar(options.name)}<span/></label>
			{getCheckNullView(options, val ? val.label : "")}
		</>}
	</div>
}

export const RTSelectMulti = ({ options = {}, handleChanged, readonly = false, css = "" }) => {

	const [focus, setFocus] = useState(false);
	const [val, setVal] = useState("");
	const [list, setList] = useState([]);

	useEffect(() => {
		setList(options.list);
	}, [options.list])

	useEffect(() => {
		if(options.list && options.value){
			setVal(options.list.filter(x => options.value.includes(x.value)));
		}
	}, [options])

	const onChanged = (e) =>{
		setVal(e);
		console.log(e);
		handleChanged && handleChanged({ target : { name : options.name, value : e.map(x => x.value) }});
	} 

	return <div className={"" + 
			(css && " " + css) +
			(focus ? " focused" : "") +
			(options.err ? " has-error" : "") }>
		{(!options.readonly && !readonly) && <>
			{options.label && <label className={options.required ? 'form-label required' : 'form-label'}>{options.label}<span/></label>}
			<Select 
				isMulti
				size={"sm"}
				name={options.name} 
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}
				value={val || ''} 
				className="text-dark"
				options={list}
				styles={options?.customStyles}
				placeholder={options?.placeholder ?? 'Select...'}
				onChange={onChanged} />
			{options.err && <label className="error" for={options.name}>{options.errmsg}</label>} 
			{options.hint && <label className="help" for={options.name}>{options.hint}</label>} 
		</>}
		{(options.readonly || readonly) && <>
			<label className='form-label'>{options.label || upperFistChar(options.name)}<span/></label>
			{getCheckNullView(options, val ? val.label : "")}
		</>}
	</div>
}
