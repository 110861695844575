import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import { Provider } from 'react-redux';
import { Store } from './store';
import * as Sentry from '@sentry/react';
import './config/i18n';

const getEnv = () => {
    if(location.href.indexOf("localhost") > -1){
        return "dev-local";
    }else if(location.href.indexOf("raydevelop") > -1){
        return "development";
    }
    return "production";
}

function FallbackComponent() {
  return (
    <div>An error has occured</div>
  )
}
Sentry.init({
    dsn: getEnv() === "production" ? 
        "https://36aa707a3a584063a581c5f432cf8b5a@o4504930404925440.ingest.sentry.io/4504947580600320" : 
        "",
    release : "1.0.0",
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    environment: getEnv(),
    tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={Store}>
    <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
    <App />
    </Sentry.ErrorBoundary>
  </Provider>,
  document.getElementById('root')
);
