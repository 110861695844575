import { useEffect, useState } from 'react';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation } from 'react-router-dom';
import { MENU, MENU_ICON } from '../config/menu';
import { ProgramConstant } from '../redux/reducers';

const LeftMenuItem = ({ user, expandleftmenu, page, item }) => {

	const dispath = useDispatch();
	const [expand, setExpand] = useState(false);
	const [active, setActive] = useState(false);
	
	useEffect(() => {
		setExpand(expandleftmenu.includes(item.key) || 
			page.key === item.key || 
			item.menus?.filter(x => x.key === page) > 0);
	},[expandleftmenu, page])
	
	useEffect(() => {
		setActive(page === item.key || item.menus?.filter(x => x.key === page) > 0);
	},[page])

	const goLink = (key = "") => {
		if(key){
			dispath({ type : ProgramConstant.PAGE, item : key });
			history.push("/" + key);
			return;
		}
			
		if(item.menus){
			dispath({ type : ProgramConstant.EXPAND, item : item.key });
		}else{
			dispath({ type : ProgramConstant.PAGE, item : item });
			history.push("/" + item.key);
		}
	}

  	return (
		<div className={expand ? "menu-item show menu-accordion" : "menu-item"}>
			<span className={active ? "menu-link active" : "menu-link"} onClick={() => goLink()}>
				{MENU_ICON(item.key)}
				<span className="menu-title">{item.label}</span>
				{item.menus && <span className="menu-arrow"></span>}
			</span>
			{expand && <div className="menu-sub menu-sub-accordion">
				{item.menus?.map(x => <div className={page.key === x.key ? "menu-item active" : "menu-item"} key={x.key}>
					<span className={"menu-link"} onClick={() => goLink(x)}>
						<span className="menu-bullet">
							<span className="bullet bullet-dot"></span>
						</span>
						<span className="menu-title">{x.label}</span>
					</span>
				</div>)}
			</div>}
		</div>
  	);
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const expandleftmenu =  state.ProgramReducer.expandleftmenu;
	const page =  state.ProgramReducer.page;

	return { user, expandleftmenu, page };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(LeftMenuItem);

