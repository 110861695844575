import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation } from 'react-router-dom';

import ContentTItle from './contenttitle';
import TopSearch from './topsearch';
import TopAlarm from './topalarm';
import ReloadButton from '../components/reload';

const ContentTop = ({ user, loginresult, page }) => {

  	return (
		<div className="header">
			<div className="container-fluid d-flex align-items-center flex-wrap justify-content-between" id="kt_header_container">
				<ContentTItle />

				<div className="d-flex align-items-center flex-shrink-0">
					{page.toolbar && page.toolbar}
					<ReloadButton />
					{/*
					<TopSearch />

					<TopAlarm />
					*/}
				</div>
			</div>
		</div>
  	);
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const loginresult =  state.AuthReducer.loginresult;
	const page =  state.ProgramReducer.page;

	return { user, loginresult, page };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(ContentTop);
