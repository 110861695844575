import { useEffect, useState } from 'react';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation } from 'react-router-dom';
import { ProgramConstant } from '../redux/reducers';

const Dashboard = ({ user }) => {

	const dispath = useDispatch();
	
	useEffect(() => {
	},[])
	
  	return (
		<div className="card">
			<div className="card-body p-5 px-lg-19 py-lg-16">
				Dashboard
			</div>
		</div>
  	);
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	return { user };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(Dashboard);

