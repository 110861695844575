import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation, useParams } from 'react-router-dom';
import { ProgramConstant, AuthConstant } from '../redux/reducers';
import { COUNTRIES } from '../config/country';
import { COLORS } from '../config/color';
import { API } from '../config/api';
import RTTable from '../libs/table';
import { RTButton } from '../libs/button';
import { RTGroup } from '../libs/string';
import { updateData, idData, fetchData } from '../libs/db';
import { RTSelect } from '../libs/input';
import { Store } from '../store'

const Setting = ({ user, allgroups }) => {

	const [t] = useTranslation(['translation']);
	const { i18n } = useTranslation(['translation'])
	const dispatch = useDispatch();

	const [ data, setData ] = useState({});
	const langs = [
		{ value : "en", label : t("English") },
		{ value : "ko", label : t("Korean") },
		{ value : "jp", label : t("Japanese") }
	];
	const [ process, setProcess ] = useState(false);
	const [ err, setErr ] = useState("");

	useEffect(() => {
		init();
		setPage();
	},[user.settings]);

	const init = () => {
		user.settings && setData({...user.settings});
	}

	const setPage = () => {
		dispatch({ type : ProgramConstant.PAGE, item : {
			label : <>{t("Setting")}</>,
			caption : <>
			</>,
			toolbar : <>
			</>
		}});
	}

	const update = async (body) => {
		const retUser = await updateData("rayteams-user", {
			_id : user._id,
			sk : "info"
		},{
			settings : {...body}
		});

		if(!retUser._id){
			setProcess(false);
			setErr(retUser.data.error);
			return;
		}
		i18n.changeLanguage(retUser?.settings?.lang);

		dispatch({ type : AuthConstant.UPDATEUSER, user : retUser });
	}

  	return <>
		<div className="card mb-5 shadow">
			<form>
				<div className="card-body row">
					<div className="mb-5">
						<label className="form-label">{t("Lanuage")}</label>
						<RTSelect options={{
							name : "lang",
							list : langs,
							value : (data.lang ? data.lang : "en")
						}} handleChanged={(e) => update({...data, lang : e.target.value})} />
					</div>
				</div>
			</form>
		</div>
	</>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const allgroups =  state.ProgramReducer.allgroups;
	return { user, allgroups };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(Setting);
