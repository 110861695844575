import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { history } from './history';
import { Route, Router, Switch, useLocation } from 'react-router-dom';
import { fetchData } from './libs/db';
import { ProgramConstant } from './redux/reducers';
import AuthRoute from './authRoute';
import Left from './common/left';
import ContentTop from './common/contenttop';
import Footer from './common/footer';
import Dashboard from './page/dashboard';
import Labs from './page/labs';
import Group from './page/group';
import Devices from './page/devices';
import Device from './page/device';
import Users from './page/users';
import MemberCreate from './page/membercreate';
import Clinics from './page/clinics';
import UserPage from './page/user';
import Projects from './page/projects';
import Orders from './page/orders';
import Setting from './page/setting';
import Login from './page/login';
import Me from './page/me';
import Partner from './page/partner';
import PartnerCreate from './page/partnercreate';
import GroupCreate from './page/groupcreate';
import Home from './page/home';

const App = ({ user }) => {
	
	const dispatch = useDispatch();
	const { i18n } = useTranslation(['translation'])
	const [ init, setInit ] = useState(false);

	useEffect(() => {
		if(user?._id){
			getInit();
			if (user?.settings?.lang) {
				i18n.changeLanguage(user?.settings?.lang);
				user?.settings?.lang && moment.locale(user.settings.lang);
			}
		}
	},[user?._id])

	const getInit = async () => {
		setInit(false);

		const retGroup = await fetchData('rayteams-group', { partnerKey : { ComparisonOperator: "EQ", AttributeValueList : [user.partnerKey] }});
		dispatch({ type : ProgramConstant.ALLGROUPS, items : retGroup.Items });

		const retMember = await fetchData('rayteams-user', { partnerKey : { ComparisonOperator: "EQ", AttributeValueList : [user.partnerKey] }});
		dispatch({ type : ProgramConstant.ALLUSERS, items : retMember.Items });

		const retProject = await fetchData('rayteams-project', { partnerKey : { ComparisonOperator: "EQ", AttributeValueList : [user.partnerKey] }});
		dispatch({ type : ProgramConstant.ALLPROJECT, items : retProject.Items });
		console.log(user.partnerKey);

		const retDevice = await fetchData('rayteams-device', { partnerKey : { ComparisonOperator: "EQ", AttributeValueList : [user.partnerKey] }});
		dispatch({ type : ProgramConstant.ALLDEVICE, items : retDevice.Items });

		const retPartner = await fetchData('rayteams-partner', { partnerKey : { ComparisonOperator: "EQ", AttributeValueList: [ user.partnerKey ] } });
		dispatch({ type : ProgramConstant.GETPARTNER, items : retPartner.Items, my : retPartner.Items.find(x => x._id === user.partnerId) || {} });

		setInit(true);
	}

  	return (
    	<>
			<Router history={history}>
				<div className="d-flex flex-column flex-root">
					{user._id && <>
						{init && <div className="page d-flex flex-row flex-column-fluid">
							<Left />
							<div className="wrapper d-flex flex-column flex-row-fluid">
								<ContentTop />
								<div className="content d-flex flex-column flex-column-fluid">
									<div className="container-fluid mb-12">
										<Switch>
											<Route path="/devices" component={Devices} />
											<Route path="/device/:_id/:_tabname?" component={Device} />
											<Route path="/users" component={Users} />
											<Route path="/user/:_id" component={UserPage} />
											<Route path="/orders" component={Orders} />
											<Route path="/settings" component={Setting} />
											<Route path="/projects" component={Projects} />
											<Route path="/addpartneruser/:_id?" component={MemberCreate} />
											<Route path="/clinics" component={Clinics} />
											<Route path="/partner" component={Partner} />
											<Route path="/labs" component={Labs} />
											<Route path="/addpartner" component={PartnerCreate} />
											<Route path="/addlab" component={GroupCreate} />
											<Route path="/updatelab/:_id" component={GroupCreate} />
											<Route path="/me" component={Me} />
											<Route path="/group/:_id/:_tabname?" component={Group} />
											<Route path="/" component={Devices} />
											{/*<Route path="/" component={Dashboard} />*/}
										</Switch>
									</div>
								</div>
								<Footer />
							</div>
						</div>}
					</>}
					{!user._id && <>
						<Switch>
							<Route path="/" component={Home} exact />
							<Route path="/login" component={Login} exact />
						</Switch>
					</>}
				</div>
			</Router>
    	</>
  	);
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;

	return { user };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(App);

