import { useCallback, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BsCheckLg } from 'react-icons/bs';
import { COLORS } from '../config/color';
import { history } from '../history';

export const RTDropDownButton = ({ title, color = "primary", buttons, css }) => {

	return <Dropdown className={css && css}>
		<Dropdown.Toggle variant={color} size='sm'>
			{title}
		</Dropdown.Toggle>
		<Dropdown.Menu>
			{buttons.map((x, idx) => <Dropdown.Item onClick={x.callback} key={idx}>
				{x.label}
			</Dropdown.Item>)}
		</Dropdown.Menu>
	</Dropdown>
}

export const RTMoreButton = ({ text = 'more', link }) => {

	const [t] = useTranslation(['translation']);

	return <RTButton options={{
		label: t(text),
		color: COLORS.PRIMARY,
		size: 'sm',
		action: () => history.push(link)
	}} />
}

export const RTButton = ({ options, valid = true, css = "", permit, formaction, style }) => {

	const visible = true;
	const [disabled, setDisabled] = useState(false);

	const clicked = () => {
		options.clickdisbled && setDisabled(true);
		if(formaction){
			formaction()
		}else{
			options.action && options.action();
		}
	}

	return <>
		{visible && <>
			{options.img && <button className='rayiconbtn'>
				<img src={options.img}
					className={'raycur'} alt=""
					onClick={clicked} />
			</button>}
			{!options.img &&
				<button className={"btn" +
					(options.color ? ' btn-' + options.color : ' btn-primary') +
					(options.icon ? ' btn-icon' : '') +
					(options.size ? ' btn-' + options.size : '') +
					(css && ' ' + css)}
					style={style}
					data-kt-indicator="on"
					onClick={clicked}
					type="button" disabled={(options.needValid && !valid) || options.disabled || disabled}>
					{options.icon && <MaterialIcon icon={options.icon} className={options.iconCss ?? ''} />}
					{options.iconobj && options.iconobj}
					{!options.ing && <>{options.label}</>}
					{options.ing && <>
						<span className="indicator-progress">
							{options.label}
							<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
						</span>
					</>}
				</button>
			}
		</>}
	</>
}

export const RTDownloadButton = ({ options, valid = true, css = "", permit, formaction, filePath, fileName, callback }) => {
	const [btnOptions, setBtnOptions] = useState(options);
	const [downloadProgress, setDownloadProgress] = useState(0);
	const [loading, setLoading] = useState(false);

	const onDownloadProgress = (event) => {
		const { loaded, total } = event
		const percent = Math.ceil(loaded / total * 100);
		setDownloadProgress(percent < 100 ? percent : 0)
	}

	const downloadFile = useCallback(async () => {
		if (!filePath) {
			return;
		}
		try {
			setLoading(true);
			let downLoadFileName = fileName;
			if (!downLoadFileName) {
				const pathArr = filePath.split('/');
				downLoadFileName = pathArr[pathArr.length - 1];
			}
			//await saveFile(filePath, downLoadFileName, onDownloadProgress);
			callback && callback(true);	
		} catch (error) {
			callback && callback(false);	
		} finally {
			setLoading(false);	
		}
	}, [callback, filePath, fileName]);

	useEffect(() => {
		setBtnOptions({ ...options, action: downloadFile, disabled: (loading ? true : options.disabled) })
	}, [downloadFile, options, loading]);

	return <div className='position-relative d-grid'>
		<RTButton options={btnOptions} valid={valid} css={css} permit={permit} formaction={formaction} />
		{downloadProgress > 0 && <div className='position-absolute start-0 end-0 top-0 bottom-0 bg-red'>
			<div className="progress position-absolute flex-fill"  style={{
				bottom: 4,
				left: 8,
				right: 8,
				height: 2
			}}>
				<div className="progress-bar" style={{ width: `${downloadProgress}%` }} />
			</div>
		</div>}
	</div>
}

export const RTCheckButton = ({ options, valid = true, css = "" }) => {

	const getCss = () => {
		var ret = "btn";
		if (options.checked) {
			ret += options.color ? ' btn-' + options.color : '';
		} else {
			ret += options.color ? ' btn-outline btn-outline-' + options.color : '';
		}
		ret += options.size ? ' btn-' + options.size : '';
		ret += css ? ' ' + css : '';
		return ret;
	}

	return <button className={getCss()}
		onClick={options.action && options.action}
		type="button" disabled={options.needValid && !valid}>
		{options.checked && <BsCheckLg className='me-2' />}
		{options.label}
	</button>
}

export const RTSubmit = ({ options, valid = true, callback, disabled }) => {

	return <>
		{options.block && <div className="d-grid gap-2 mt-2">
			<button className={"btn" +
				(options.color ? ' btn-' + options.color : '') +
				(options.size ? ' btn-' + options.size : '')}
				onClick={callback && callback}
				type="button" disabled={disabled || (options.needValid && !valid)}>
				{options.icon && <MaterialIcon icon={options.icon} />}
				{options.label}
			</button>
		</div>}
		{!options.block && <button className={"btn" +
			(options.color ? ' btn-' + options.color : '') +
			(options.size ? ' btn-' + options.size : '')}
			onClick={callback && callback}
			type="button" disabled={disabled || (options.needValid && !valid)}>
			{options.icon && <MaterialIcon icon={options.icon} />}
			{options.label}
		</button>}
	</>
}
