import axios from 'axios';
import { useEffect, useState } from 'react';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation, useParams } from 'react-router-dom';
import { ProgramConstant, AuthConstant } from '../redux/reducers';
import { COUNTRIES } from '../config/country';
import { API } from '../config/api';
import { RTButton } from '../libs/button';
import RTTable from '../libs/table';
import { idData, fetchData } from '../libs/db';
import { Store } from '../store'

const Me = ({ user, allgroups, mypartner }) => {

	const dispatch = useDispatch();
	const { _id, _tabname } = useParams("overview");

	const [ info, setInfo ] = useState({});
	const [ data, setData ] = useState({});
	const [ items, setItems ] = useState([]);
	const [ list, setList ] = useState([]);
	const [ process, setProcess ] = useState(false);
	const [ err, setErr ] = useState("");
	const [ search, setSearch ] = useState("");
    const domain = window.location.href.indexOf(".rayteams.com") > -1 ? "rayteams.com" : "raydevelop.com";
	
	useEffect(() => {
		if(user?._id){
			init();
			setData({...user});
		}
	},[user, mypartner]);

	const init = async () => {

		const grp = allgroups.find(g => g._id === _id);
		dispatch({ type : ProgramConstant.PAGE, item : {
			label : <>
				{user.name}
			</>,
			caption : <>
				<span className="me-2 fw-bolder">
					{user.email}
				</span>
			</>
		}});
	}

	const getIdToken = () => {
		const t = JSON.parse(localStorage.getItem("token"));
		return t?.IdToken;
	}

	const updateUser = async () => {
		setProcess(true);

        //const env = location.href.indexOf("localhost") > -1 ? "-development" : "";
		var ret = await axios.post("https://api-ap-northeast-2-user." + domain + "/users/" + user._id,  {
			data : { name : data.name } } , { headers : { token : getIdToken() } });
		
		console.log(ret.data.data);
		dispatch({ type : AuthConstant.UPDATEUSER, user : ret.data.data });

		setProcess(false);
	}
	
	const updateUserPassword = async () => {
		setProcess(true);
		
		if(data.password !== data.password2){
			setErr("Password not matched");
			return;
		}

		var ret = await axios.post("https://api-ap-northeast-2-user." + domain + "/changepassworduser",  {
			data : { password : data.password, email : user.email, sub : user._id } } , { headers : { token : getIdToken() } });
		
		setData({...user});

		setProcess(false);
	}
	
  	return <>
		{err && <div className="alert alert-warning shadow">{err}</div>}
		<div className="card mb-5 shadow">
			<div className="card-header">
				<div className="card-title">Account Information</div>
			</div>
			<div className="card-body">
				<form>
					<div className="mb-5">
						<label className="form-label">Partner</label>
						<div>
							<span className="fs-4">{mypartner.name}</span>
						</div>
					</div>
					<div className="mb-5">
						<label className="form-label">Email</label>
						<div>
							<span className="fs-4">{data.email}</span>
						</div>
					</div>
					<div className="mb-5">
						<label className="form-label">Name</label>
						<input 
							type="text" 
							className="form-control form-control-solid" 
							placeholder="Name" 
							value={data.name}
							onChange={(e) => setData({...data, name : e.target.value})}/>
					</div>
					<div>
						<RTButton options={{
							label : "Update",
							action : () => updateUser()
						}} />
					</div>
				</form>
			</div>
		</div>
		<div className="card mb-5 shadow">
			<div className="card-header">
				<div className="card-title">Change password</div>
			</div>
			<div className="alert alert-info rounded-0">
				<h4>The password has the following rules.</h4>
				<ul className='mt-2 mb-0'>
					<li>At least 8 characters</li>
					<li>At least one uppercase letter</li>
					<li>One or more lowercase letters</li>
					<li>One or more numbers</li>
					<li>One or more special characters</li>
				</ul>
			</div>
			<div className="card-body">
				<form>
					<div className="mb-5">
						<label className="form-label">Password</label>
						<input type="password" 
							className="form-control form-control-solid" 
							placeholder="Password" 
							value={data.password || ""}
							onChange={(e) => setData({...data, password : e.target.value})}/>
					</div>
					<div className="mb-5">
						<label className="form-label">Password Confirm</label>
						<input type="password" 
							className="form-control form-control-solid" 
							placeholder="Password" 
							value={data.password2 || ""}
							onChange={(e) => setData({...data, password2 : e.target.value})}/>
					</div>
					<div>
						<RTButton options={{
							color : "danger",
							label : "Update Password",
							action : () => updateUserPassword()
						}} />
					</div>
				</form>
			</div>
		</div>
	</>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const allgroups =  state.ProgramReducer.allgroups;
	const mypartner =  state.ProgramReducer.mypartner;

	return { user, allgroups, mypartner };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(Me);
