import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { COGNITO } from '../libs/cognito'
import { useTranslation } from 'react-i18next';
import { FaCrown, FaStore, FaUserTie } from 'react-icons/fa';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation, useParams } from 'react-router-dom';
import { ProgramConstant } from '../redux/reducers';
import { COUNTRIES } from '../config/country';
import { COLORS } from '../config/color';
import { API } from '../config/api';
import RTTable from '../libs/table';
import { aws_con, aws_con_admin } from '../libs/db';
import { RTButton } from '../libs/button';
import { RTGroup, XName, XEmail } from '../libs/string';
import AWS from 'aws-sdk';
import { idData, fetchData } from '../libs/db';
import CompGroupList from '../components/grouplist';
import CompProjectList from '../components/projectlist';
import { Store } from '../store'

const User = ({ user, allusers, _id, region = "ap-northeast-2", card = false }) => {

	const dispatch = useDispatch();

	const [t] = useTranslation(['translation']);
	const [ info, setInfo ] = useState({});
	const [ edit, setEdit ] = useState(false);
	const [ data, setData ] = useState({
		name : "",
	});
	const [ process, setProcess ] = useState(false);

	useEffect(() => {
		if(_id){
			getUser();
		}
	},[_id]);

	const getUser = async () => {
		const user = allusers.find(x => x._id === _id && x.email);
		if(user){
			setInfo(user);
			return;
		}

		setProcess(true);

		aws_con();

		const params = {
			Username: _id,
			UserPoolId: COGNITO[region].poolId,
		};

		AWS.config.update({ region: region });

		var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
		try {
			var item = await cognitoidentityserviceprovider
				.adminGetUser(params)
				.promise();
			var ret = {};
			item?.UserAttributes.map((x) => {
				ret[x.Name.replace("custom:", "")] = x.Value;
			});
			ret._id = ret.sub;
			setInfo(ret);
			dispatch({ type : ProgramConstant.GETUSER, user : ret });
		} catch (err) {
			console.log(err);
		}

		setProcess(false);
	};

  	return <>
		{!card && <>
			{process && <div className="d-flex align-items-center fw-bolder fs-4">
				...
			</div>}
			{!process && <div>
				{info.groupId === user.partnerKey ? 
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="#5014d0"></path>
						<path d="M12.0006 11.1542C13.1434 11.1542 14.0777 10.22 14.0777 9.0771C14.0777 7.93424 13.1434 7 12.0006 7C10.8577 7 9.92348 7.93424 9.92348 9.0771C9.92348 10.22 10.8577 11.1542 12.0006 11.1542Z" fill="#5014d0"></path>
						<path d="M15.5652 13.814C15.5108 13.6779 15.4382 13.551 15.3566 13.4331C14.9393 12.8163 14.2954 12.4081 13.5697 12.3083C13.479 12.2993 13.3793 12.3174 13.3067 12.3718C12.9257 12.653 12.4722 12.7981 12.0006 12.7981C11.5289 12.7981 11.0754 12.653 10.6944 12.3718C10.6219 12.3174 10.5221 12.2902 10.4314 12.3083C9.70578 12.4081 9.05272 12.8163 8.64456 13.4331C8.56293 13.551 8.49036 13.687 8.43595 13.814C8.40875 13.8684 8.41781 13.9319 8.44502 13.9864C8.51759 14.1133 8.60828 14.2403 8.68991 14.3492C8.81689 14.5215 8.95295 14.6757 9.10715 14.8208C9.23413 14.9478 9.37925 15.0657 9.52439 15.1836C10.2409 15.7188 11.1026 15.9999 11.9915 15.9999C12.8804 15.9999 13.7421 15.7188 14.4586 15.1836C14.6038 15.0748 14.7489 14.9478 14.8759 14.8208C15.021 14.6757 15.1661 14.5215 15.2931 14.3492C15.3838 14.2312 15.4655 14.1133 15.538 13.9864C15.5833 13.9319 15.5924 13.8684 15.5652 13.814Z" fill="#5014d0"></path>
					</svg>
					: 
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-add-lab">
						<path d="M11 2.375L2 9.575V20.575C2 21.175 2.4 21.575 3 21.575H9C9.6 21.575 10 21.175 10 20.575V14.575C10 13.975 10.4 13.575 11 13.575H13C13.6 13.575 14 13.975 14 14.575V20.575C14 21.175 14.4 21.575 15 21.575H21C21.6 21.575 22 21.175 22 20.575V9.575L13 2.375C12.4 1.875 11.6 1.875 11 2.375Z" fill="#5014d0"/>
					</svg>
				}
				<a href="#" onClick={() => history.push("/user/" + info._id)} className="mx-2">{XName(info?.name)}</a>
				<span className='text-gray-600'>{XEmail(info?.email)}</span>
				{(info.groupId !== user.partnerKey && info.userType === "manager") && <FaUserTie className="ms-2"/>}
			</div>}
		</>}
		{card && <div className="card mb-5 shadow">
			<div className="card-header">
				<div className="card-title">{user.name}</div>
				<div className="card-toolbar">
					{edit && <>
						<RTButton options={{
							label : t("Cancel"),
							color : COLORS.SECONDARY,
							size : "sm",
							action : () => setEdit(false)
						}} css="me-2" />
						<RTButton options={{
							label : <>
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
									<path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
								</svg> {t("Update")}
							</>,
							size : "sm",
							action : () => update(),
							needValid : true
						}} valid={data.name} />
					</>}
					{!edit && <RTButton options={{
						label : <>
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor"></path>
								<path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor"></path>
								<path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor"></path>
							</svg> {t("Edit")}
						</>,
						size : "sm",
						action : () => setEdit(true)
					}} />}
				</div>
			</div>
			<div className="card-body">
				<div className="mb-5">
					<label className="form-label fs-7 text-gray-900">{t("Email")}</label>
					<div className="text-gray-600">
						{user.email}
					</div>
				</div>
				<div className="mb-5">
					<label className="form-label fs-7 text-gray-900">{t("Name")}</label>
					<div className="text-gray-600">
						{!edit && user.name}
						{edit && <input 
							type="text" 
							className="form-control form-control-solid" 
							placeholder={t("Enter account name")}
							value={data.name}
							onChange={(e) => setData({...data, name : e.target.value})} />}
					</div>
				</div>
				<div className="">
					<label className="form-label fs-7 text-gray-900">{t("Created")}</label>
					<div className="text-gray-600">
						{moment(user.created).format("L")}
					</div>
				</div>
			</div>
		</div>}
	</>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const allusers =  state.ProgramReducer.allusers;
	return { user, allusers };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(User);
