import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation } from 'react-router-dom';
import { ProgramConstant } from '../redux/reducers';
import { API } from '../config/api';
import { nFormat } from '../libs/string';
import { RTButton } from '../libs/button';
import RTTable from '../libs/table';
import { fetchData } from '../libs/db';

const Labs = ({ user, allgroups }) => {

	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const [ items, setItems ] = useState([]);
	const [ list, setList ] = useState([]);
	const [ process, setProcess ] = useState(false);
	const [ err, setErr ] = useState("");
	const [ hasMember, setHasMember ] = useState(true);
	const [ search, setSearch ] = useState("");
	
	useEffect(() => {
		dispatch({ type : ProgramConstant.PAGE, item : {
			label : t("Lab"),
			caption : t("Manage lab information")
		}});
		if(search){
			setList(allgroups.filter(x => hasMember ? x.hasMember : true).filter(x => x.name.indexOf(search) > -1 || x.address.indexOf(search) > -1));
		}else{
			setList(allgroups.filter(x => hasMember ? x.hasMember : true));
		}
	},[search, allgroups, hasMember])

	const headers = [
		{ link : "_id", value : "name", label : t("Name"), css : "text-start w-300px", linkpath : "/group"  },
        //{ value : "partnerId", label : t("Partner"), css : "text-start", type : "PARTNER" },
        { value : "address", label : t("Address"), css : "text-start" },
        { value : "countryCode", label : t("Country"), css : "", type : "COUNTRY" }
	]
	
  	return <div className="card">
		<div className="card-header">
			<div className="card-title">
				<div className="d-flex align-items-center position-relative my-1">
					<span className="svg-icon svg-icon-1 position-absolute ms-6">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
							<rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
							<path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path>
						</svg>
					</span>
					<input 
						type="text" 
						className="form-control form-control-solid w-250px ps-14" 
						placeholder="Search keyword" onChange={(e) => setSearch(e.target.value)} />

					{/*<div className="form-check form-switch form-check-custom form-check-solid ms-4">
						<input className="form-check-input" 
							type="checkbox" 
							value={hasMember} 
							checked={hasMember ? "checked" : ""} 
							onChange={() => setHasMember(!hasMember)}/>
						<label className="form-check-label fs-6 text-gray-800">
							{hasMember ? "Active" : "All"}
						</label>
					</div>*/}

				</div>
			</div>
			<div className="card-toolbar">
				<span className="ms-4 me-4 text-gray-800">Total : {list && nFormat(list.length)}</span>
				<div>
					<RTButton options={{
						label : t("Add a lab"),
						action : () => history.push("/addlab")
					}}/>
				</div>
			</div>
		</div>
		<div className="card-body">
			<RTTable 
				headers={headers} 
				items={list} 
				loading={process} />
		</div>
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const allgroups =  state.ProgramReducer.allgroups;
	return { user, allgroups };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(Labs);
