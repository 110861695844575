import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RotatingSquare } from 'react-loader-spinner';
import { COLORS } from '../config/color';
import { RTButton } from './button';

export const RTPanelNormalConfirm = ({
	bg = 'warning',
	title,
	contents,
	okcancel,
	callbackok,
	callbackcancel,
	buttons = [] }) => {

	const [t] = useTranslation(['translation']);

	return <>
		<div className={'card bg-' + bg }>
			<div className='card-header'>
				<div className='card-title bold fw-3'>{title}</div>
			</div>
			<div className='mt-2 card-body'>
				{contents}
				<div className='btns mt-3'>
					{okcancel && <>
						<RTButton options={{
							label: '삭제',
							color: COLORS.PRIMARY,
							action: callbackok
						}} css="me-1" />
						<RTButton options={{
							label: '취소',
							color: COLORS.LIGHT,
							action: callbackcancel
						}} />
					</>}
					{buttons.map((y, cdx) => <React.Fragment key={cdx}>
						{y.type === BUTTONS.NORMALBUTTON && <RTButton options={y.options} />}
					</React.Fragment>)}
				</div>
			</div>
		</div>
	</>
}

export const RTPanelNoData = ({
	title = "Notice",
	notitle = false,
	contents = "No record",
	noborder = false,
	css = "",
	buttons = [] }) => {

	const [t] = useTranslation(['translation']);
	const [obj, setObj] = useState({
		title: title || "헛!",
		contents: contents || "데이터가 존재하지 않습니다."
	});

	return <>
		{!noborder && <div className={css ? 'card ' + css : 'card'}>
			<div className='card-body text-center py-12'>
				{!notitle && <h3 className='card-title bold'>{obj.title}</h3>}
				<div className='text-center'>{obj.contents}</div>
			</div>
		</div>}
		{noborder && <div className={css}>
			{obj.title && <div className='p-3 pb-0'>
				<h4 className='text-center'>{obj.title}</h4>
			</div>}
			<div className='text-center pb-3'>
				{obj.contents}
				{buttons.length > 0 && <div className='btns mt-3'>
					{buttons.map((y, cdx) => <React.Fragment key={cdx}>
						{y.type === BUTTONS.NORMALBUTTON && <RTButton options={y.options} />}
					</React.Fragment>)}
				</div>}
			</div>
		</div>}
	</>
}

export const RTPanelLoading = ({
	title = "",
	notitle = false,
	contents = "",
	noborder = false,
	css = "",
	titleColor = "",
	subColor = "",
	buttons = [] }) => {

	const [t] = useTranslation(['translation']);
	const [obj, setObj] = useState({
		title: title || "잠시만요!",
		contents: contents || "데이터를 가져오는 중입니다."
	});

	return <>
		{!noborder && <div className={css ? 'rayloadingbox card ' + css : 'rayloadingbox card'}>
			<div className='card-body text-center justify-content-center d-flex flex-rows align-items-center'>
				<RotatingSquare
					ariaLabel="rotating-square"
					visible={true}
					color="#000"
					strokeWidth="4"
				/>
				<div className='text-start'>
					<div className='fs-4' style={{ color: titleColor ?? '' }}>{obj.title}</div>
					<div className={!subColor ? 'text-gray-600' : ''} style={{ color: subColor ?? '' }}>{obj.contents}</div>
				</div>
			</div>
		</div>}
		{noborder && <div className={css + " rayloadingbox "}>
			<div className='text-center justify-content-center d-flex flex-rows align-items-center'>
				<RotatingSquare
					ariaLabel="rotating-square"
					visible={true}
					color="#000"
					strokeWidth="4"
				/>
				<div className='text-start'>
					<div className='fs-4' style={{ color: titleColor ?? '' }}>{obj.title}</div>
					<div className={!subColor ? 'text-gray-600' : ''} style={{ color: subColor ?? '' }}>{obj.contents}</div>
				</div>
			</div>
		</div>}
	</>
}

export const RTPanelLoadingOverlay = ({ position = 'fixed', titleColor = 'white', subColor = '#8e92b9', noborder = false }) => {
	return <div
		className='d-flex align-items-center justify-content-center'
		style={{ position: position, left: 0, top: 0, right: 0, bottom: 0, zIndex: 1000, backgroundColor: 'rgba(11, 13, 32, 0.75)' }}>
		<RTPanelLoading titleColor={titleColor} subColor={subColor} noborder />
	</div>
}
