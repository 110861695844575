import axios from 'axios';
import { v4 } from 'uuid';
import { useEffect, useState } from 'react';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation, useParams } from 'react-router-dom';
import { ProgramConstant } from '../redux/reducers';
import { COUNTRIES, getRegion } from '../config/country';
import { API } from '../config/api';
import { COLORS } from '../config/color';
import RTTable from '../libs/table';
import { RTGroup } from '../libs/string';
import { RTCountrySelect } from '../libs/input';
import { idData, fetchData, insertData, updateData } from '../libs/db';
import { RTButton } from '../libs/button';
import PartnerTree from '../components/treepartners';
import { Store } from '../store'

const PartnerCreate = ({ user, allgroups, mypartner, allpartner }) => {

	const dispatch = useDispatch();

	const [ parentPartner, setParentPartner ] = useState({});
	const [ data, setData ] = useState({});
	const [ process, setProcess ] = useState(false);
	const [ err, setErr ] = useState("");

	useEffect(() => {
		init();
	},[mypartner]);

	const init = async () => {
		setParentPartner(mypartner);
		dispatch({ type : ProgramConstant.PAGE, item : {
			label : "Add a sub-partner",
			caption : "Add a sub-partner and manager account"
		}});
	}

	const createGroup = async () => {
		setProcess(true);
		
		//user create
		const region = 'ap-northeast-2';
		const partnerId = v4();
		const newUser = {
			name : data.username,
			email : data.useremail,
			password : data.userpassword,
			region,
			grouptype : "partner",
			groupId : user.partnerKey,
			partnerId,
			partnerKey : user.partnerKey,
		}
        const domain = location.href.indexOf(".rayteams.com") > -1 ? "rayteams.com" : "raydevelop.com";
		var retMember = await axios.post("https://api-ap-northeast-2-user." + domain + "/user",  {
			data : newUser } , {
				headers : { 
					'x-ray-device' : "RAYLINK" 
				} 
			}
		);

		if(retMember.data.status === "fail"){
			setProcess(false);
			setErr(retMember.data.error);
			return;
		}

		//partner create
		const newPartner = {
			_id: partnerId,
			sk: "info",
			created: new Date().getTime(),
			logo: mypartner.logo,
			name: data.name,
			parentId: parentPartner._id,
			partnerKey: user.partnerKey,
			type: "dealer",
		};

		const retPartner = await insertData("rayteams-partner", newPartner);

		dispatch({ type : ProgramConstant.ADDUSER, user : retMember.data.data });
		dispatch({ type : ProgramConstant.PARTNERADD, partner : retPartner });

		setProcess(false);

		history.push("/partner");
	}
	
  	return <>
		{err && <div className="alert alert-warning shadow">{err}</div>}
		<div className="card mb-5 shadow">
			<div className="card-header">
				<div className="card-title">
					<input 
						type="text" 
						className="w-300px" 
						placeholder="Enter sub-partner name" 
						value={data.name}
						onChange={(e) => setData({...data, name : e.target.value})} />
					<span className="ms-2"> belong to a partner '{parentPartner.name}'</span>.
				</div>
				<div className="card-toolbar">
					<RTButton options={{
						label : "Cancel",
						color : COLORS.SECONDARY,
						action : () => history.push("/partner"),
					}} css="me-2" />
					<RTButton options={{
						label : "Create",
						action : () => createGroup(),
						needValid : true
					}} valid={!!(data.name && data.username && data.useremail && data.userpassword)} />
				</div>
			</div>
			<form>
			<div className="card-body row">
				<div className="mb-5 col-6">
					<label className="form-label fs-3">Choose Parent Partner</label>
					<div>
						<PartnerTree 
							selected={parentPartner._id}
							onChanged={(e, id) => setParentPartner(allpartner.find(x => x._id === id))}/>
					</div>
				</div>
				<div className="col-6">
					<div className="mb-5">
						<label className="form-label fs-3">Partner Manager Account</label>
						<div className="alert alert-primary">
							{"This account has administrative privileges under the partner"} {data.name ? <span className="fw-bolder">({data.name})</span> : ""} {" being created."}
						</div>
					</div>
					<div className="mb-5">
						<label className="form-label">Partner manager name</label>
						<input 
							type="text" 
							className="form-control form-control-solid" 
							placeholder="Manager Name" 
							value={data.username}
							onChange={(e) => setData({...data, username : e.target.value})}/>
					</div>
					<div className="mb-5">
						<label className="form-label">Partner manager email</label>
						<input 
							type="email" 
							className="form-control form-control-solid" 
							placeholder="Manager Email" 
							value={data.useremail}
							onChange={(e) => setData({...data, useremail : e.target.value})}/>
					</div>
					<div className="mb-5">
						<label className="form-label">Partner manager password</label>
						<input type="password" 
							className="form-control form-control-solid" 
							placeholder="Password" 
							value={data.userpassword || ""}
							onChange={(e) => setData({...data, userpassword : e.target.value})}/>
					</div>
					<div className="mb-5">
						<label className="form-label">Partner manager password confirm</label>
						<input type="password" 
							className="form-control form-control-solid" 
							placeholder="Password" 
							value={data.userpassword2 || ""}
							onChange={(e) => setData({...data, userpassword2 : e.target.value})}/>
					</div>
				</div>
				<div>
				</div>
			</div>
			</form>
		</div>
		
	</>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const allgroups =  state.ProgramReducer.allgroups;
	const allpartner =  state.ProgramReducer.allpartner;
	const mypartner =  state.ProgramReducer.mypartner;

	return { user, allgroups, mypartner, allpartner };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(PartnerCreate);
