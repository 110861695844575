import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation } from 'react-router-dom';
import { ProgramConstant } from '../redux/reducers';
import { FaSave, FaEdit, FaWindowClose, FaCheckCircle, FaStopCircle } from 'react-icons/fa';
import { API } from '../config/api';
import { RTSize, RTGroup, RTPartner } from '../libs/string';
import RTTable from '../libs/table';
import { nFormat } from '../libs/string';
import { RTPartnerSelect, RTSelect, RTSelectMulti } from '../libs/input';
import { RTButton } from '../libs/button';
import Clinic from '../components/clinic';
import { fetchData, updateData } from '../libs/db';
import { Store } from '../store'

const DeviceRow = ({ user, allgroups, headers, row, rowidx, allpartner, key }) => {

	const dispatch = useDispatch();
	const [ items, setItems ] = useState([]);
	const [ list, setList ] = useState([]);
	const [ rowData, setRowData ] = useState({});
	const [ data, setData ] = useState({});
	const [ process, setProcess ] = useState(false);
	const [ err, setErr ] = useState("");
	const [ search, setSearch ] = useState("");
	const [ edit, setEdit ] = useState(false);

	useEffect(() => {
		setRowData(row);
	}, [row]);

	const getLabSel = () => {
		return <RTSelectMulti options={{
			name : "labs",
			value : data.labs,
			list : allgroups.filter(x => x.partnerId === data.partnerId).map(x => ({ value : x._id, label : x.name }))
		}} handleChanged={(e) => setData({...data, labs : e.target.value})} />
	}

	const getTFToggle = () => {
		return <div className="form-check form-switch form-check-custom form-check-solid ms-4" style={{
			display : "block"
		}}>
			<input className="form-check-input" 
				type="checkbox" 
				value={data.isactive} 
				checked={data.isactive ? "checked" : ""} 
				onChange={() => setData({...data, isactive : !data.isactive})}/>
			<label className="form-check-label fs-6 text-gray-800">
			</label>
		</div>
	}

	const getTFToggleSharable = () => {
		return <div className="form-check form-switch form-check-custom form-check-solid ms-4" style={{
			display : "block"
		}}>
			<input className="form-check-input" 
				type="checkbox" 
				value={data.issharable} 
				checked={data.issharable ? "checked" : ""} 
				onChange={() => setData({...data, issharable : !data.issharable})}/>
			<label className="form-check-label fs-6 text-gray-800">
			</label>
		</div>
	}

	const getPartnerSel = () => {
		return <RTPartnerSelect options={{
			name : "partnerId",
			value : data.partnerId
		}} handleChanged={(e) => setData({...data, partnerId : e.target.value})} />
	}

	const getVal = (item, header) => {
		var ret = item[header.value];
		if (header.type === "DATE") {
			ret = ret ? moment.unix(ret / 1000).format("yyyy-MM-DD HH:mm:ss") : "";
		} else if (header.type === "DATESHORT") {
			ret = ret ? moment(ret).format("yyyy-MM-DD") : "";
		} else if (header.value === "isactive") {
			ret = edit ? getTFToggle() : (ret ? 
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-2h">
				<rect opacity="1" x="2" y="2" width="20" height="20" rx="10" fill="#5014d0"></rect>
				<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="#fff"></path>
				</svg> : 
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-2h">
				<rect opacity="0.1" x="2" y="2" width="20" height="20" rx="10" fill="#000"></rect>
				<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="#b5b5b5"></path>
				</svg>
			);
		} else if (header.value === "issharable") {
			ret = edit ? getTFToggleSharable() : (ret ? 
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-2h">
				<rect opacity="1" x="2" y="2" width="20" height="20" rx="10" fill="#5014d0"></rect>
				<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="#fff"></path>
				</svg> : 
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-2h">
				<rect opacity="0.1" x="2" y="2" width="20" height="20" rx="10" fill="#000"></rect>
				<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="#b5b5b5"></path>
				</svg>
			);
		} else if (header.value === "partnerId") {
			ret = edit ? getPartnerSel() : <RTPartner pid={ret} readonly={edit} />
		} else if (header.type === "CLINIC") {
			ret = <Clinic cid={ret} />
		} else if (header.value === "labs") {
			const gItems = [];
			if(edit){
				ret = getLabSel();
			}else{
				const gItems = [];
				ret.map((x, idx) => {
					gItems.push(<RTGroup gid={x} readonly={edit} key={"span-" + x} />);
					if(idx < ret.length - 1)
						gItems.push(<span key={"sp-" + x}>, </span>);
				});
                if(gItems.length === 0 )
                    gItems.push(<span className="font-italic text-gray-500">All Labs</span>);
				ret = gItems;
			}
		}
		if (header.link && !edit) {
			if(Array.isArray(header.link)){
				const addPath = header.link.map(x => item[x]).join("/");
				ret = <Link to={header.linkpath + '/' + addPath}>{ret}</Link>
			}else{
				if (header.linkappend) {
					ret = <Link to={header.linkappend + item[header.link]}>{ret}</Link>
				} else if (header.linkMultivalue) {
					const sItem = ret.split(",");
					const rItems = [];
					for (const c of sItem) {
						rItems.push(<Link to={header.linkpath + '/' + c.trim()}>{c + ", "}</Link>)
					}
					ret = <>
						{rItems}
					</>
				} else {
					ret = <> <Link to={header.linkpath + '/' + item[header.link]}>{ret}</Link> </>
				}
			}
		} else if (header.callback && callback) {
			ret = <Link to="#" onClick={() => callback(item)}>{ret}</Link>
		}
		return ret;
	}

	const getRow = (item, rowindex) => {
		var ret = [];
		headers.map((x, idx) => {
			if(x.value)
				ret.push(<td key={'td-' + rowindex + "-" + idx} 
					className={(x.css ? x.css + ' py-4 align-middle' : x.css) + (edit ? " px-4" : "")}>
					{getVal(item, x)}</td>)
			if(!x.value)
				ret.push(<td className="py-4 align-middle px-4" key={'td-' + rowindex + "-" + idx}>
					{edit && <>
						<span  onClick={() => save()} className="cursor-pointer">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="cursor-pointer icon-2-5h me-1">
								<rect opacity="1" x="2" y="2" width="20" height="20" rx="5" fill="#5014d0"/>
								<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="#fff"/>
							</svg>
						</span>
						<span onClick={() => setEdit(false)} className="cursor-pointer">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="cursor-pointer icon-2-5h">
								<rect opacity="1" x="2" y="2" width="20" height="20" rx="5" fill="#f1416c"/>
								<rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="#fff"/>
								<rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="#fff"/>
							</svg>
						</span>
					</>}
					{!edit && <span  className="cursor-pointer" onClick={() => { setData(item); setEdit(true); }}>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="cursor-pointer icon-2h hover-color-primary">
							<path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor"/>
							<path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="#a6a7b1"/>
							<path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="#a6a7b1"/>
						</svg>
					</span>
					}
				</td>);
		});
		return ret;
	}

	const save = async () => {
		setProcess(true);
		const upData = {
			labs : data.labs || [],
			isactive : data.isactive,
			issharable : data.issharable,
			partnerId : data.partnerId
		}
		const ret = await updateData("rayteams-device", { sn : rowData.sn, sk : "info" }, upData);
		dispatch({ 
			type : ProgramConstant.UPDATEDEVICE, 
			item : ret
		});
		setProcess(false);
		setEdit(false);
	}
	
  	return <tr className={edit ? "bg-light-primary py-8" : "py-4"}>
		{rowData?.sn && getRow(rowData, rowidx)}
	</tr>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const allgroups =  state.ProgramReducer.allgroups;
	const allpartner =  state.ProgramReducer.allpartner;

	return { user, allgroups, allpartner };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(DeviceRow);
