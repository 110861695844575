import axios from 'axios';
import { v4 } from 'uuid';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation, useParams } from 'react-router-dom';
import { ProgramConstant } from '../redux/reducers';
import { COUNTRIES, getRegion } from '../config/country';
import { API } from '../config/api';
import RTTable from '../libs/table';
import { RTGroup } from '../libs/string';
import { RTCountrySelect } from '../libs/input';
import { idData, fetchData, insertData, updateData } from '../libs/db';
import PartnerTree from '../components/treepartners';
import CompPartnerUserList from '../components/partneruserlist';
import CompPartnerInfo from '../components/partnerinfo';
import CompGroupList from '../components/grouplist';
import { RTButton } from '../libs/button';
import { Store } from '../store'

const Partner = ({ user, allgroups, mypartner, allpartner }) => {

	const dispatch = useDispatch();

	const [t] = useTranslation(['translation']);
	const [ data, setData ] = useState({});
	const [ process, setProcess ] = useState(false);
	const [ partner, setPartner ] = useState({});
	const [ err, setErr ] = useState("");

	useEffect(() => {
		init();
	},[mypartner]);

	const init = () => {
		dispatch({ type : ProgramConstant.PAGE, item : {
			label : <>Partner : {mypartner?.name}</>,
			caption : t('Manage your organization'),
            /*
			toolbar : <RTButton options={{
				label : t("Add sub-partner"),
				action : () => history.push("/addpartner")
			}} />
            */
		}});
		const qs = queryString.parse(window.location.search);
		if(qs?.partner){
			setPartner(allpartner.find(x => x._id === qs?.partner));
			return;
		}else{
			setPartner(mypartner);
		}
	}
	
  	return <div className="row">
		<div className="card col-4 mb-5 shadow">
			<CompPartnerInfo partner={partner} title={t("Information")} />
            {/*
			<div className="card-header">
				<div className="card-title">{t("Partners")}</div>
			</div>
			<div className="card-body py-2 px-0">
				<PartnerTree 
					selected={partner._id}
					onChanged={(e, id) => setPartner(allpartner.find(x => x._id === id))}/>
			</div>
            */}
		</div>
		<div className="col-8">
			<CompPartnerUserList partner={partner} title={t("Member(s)")} partner={partner} />
			<CompGroupList groups={allgroups.filter(x => x.partnerId === partner._id)} title={"Lab(s)"} partner={partner} />
		</div>
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const allgroups =  state.ProgramReducer.allgroups;
	const mypartner =  state.ProgramReducer.mypartner;
	const allpartner =  state.ProgramReducer.allpartner;

	return { user, allgroups, mypartner, allpartner };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(Partner);
