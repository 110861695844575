import { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { FaRedo } from 'react-icons/fa';
import { COLORS } from '../config/color';
import { fetchData } from '../libs/db';
import { ProgramConstant } from '../redux/reducers';
import { history } from '../history';

const ReloadButton = ({ user }) => {

	const dispatch = useDispatch();
	const [ process, setProcess ] = useState(false);

	const reload = async () => {
		setProcess(true);

		const retGroup = await fetchData('rayteams-group', { partnerKey : { ComparisonOperator: "EQ", AttributeValueList : [user.partnerKey] }});
		dispatch({ type : ProgramConstant.ALLGROUPS, items : retGroup.Items });

		const retMember = await fetchData('rayteams-user', { partnerKey : { ComparisonOperator: "EQ", AttributeValueList : [user.partnerKey] }});
		dispatch({ type : ProgramConstant.ALLUSERS, items : retMember.Items });

		const retProject = await fetchData('rayteams-project', { partnerKey : { ComparisonOperator: "EQ", AttributeValueList : [user.partnerKey] }});
		dispatch({ type : ProgramConstant.ALLPROJECT, items : retProject.Items });
		console.log(user.partnerKey);

		const retDevice = await fetchData('rayteams-device', { partnerKey : { ComparisonOperator: "EQ", AttributeValueList : [user.partnerKey] }});
		dispatch({ type : ProgramConstant.ALLDEVICE, items : retDevice.Items });

		const retPartner = await fetchData('rayteams-partner', { partnerKey : { ComparisonOperator: "EQ", AttributeValueList: [ user.partnerKey ] } });
		dispatch({ type : ProgramConstant.GETPARTNER, items : retPartner.Items, my : retPartner.Items.find(x => x._id === user.partnerId) || {} });

		setProcess(false);
	}

	return <a href="#" className="btn btn-icon btn-light pulse">
		{process && <span className="pulse-ring"></span>}
		<span className='cursor-pointer' onClick={() => { !process && reload() }}>
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M14.5 20.7259C14.6 21.2259 14.2 21.826 13.7 21.926C13.2 22.026 12.6 22.0259 12.1 22.0259C9.5 22.0259 6.9 21.0259 5 19.1259C1.4 15.5259 1.09998 9.72592 4.29998 5.82592L5.70001 7.22595C3.30001 10.3259 3.59999 14.8259 6.39999 17.7259C8.19999 19.5259 10.8 20.426 13.4 19.926C13.9 19.826 14.4 20.2259 14.5 20.7259ZM18.4 16.8259L19.8 18.2259C22.9 14.3259 22.7 8.52593 19 4.92593C16.7 2.62593 13.5 1.62594 10.3 2.12594C9.79998 2.22594 9.4 2.72595 9.5 3.22595C9.6 3.72595 10.1 4.12594 10.6 4.02594C13.1 3.62594 15.7 4.42595 17.6 6.22595C20.5 9.22595 20.7 13.7259 18.4 16.8259Z" fill="currentColor"/>
				<path opacity="0.3" d="M2 3.62592H7C7.6 3.62592 8 4.02592 8 4.62592V9.62589L2 3.62592ZM16 14.4259V19.4259C16 20.0259 16.4 20.4259 17 20.4259H22L16 14.4259Z" fill="currentColor"/>
			</svg>
		</span>
	</a>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;

	return { user };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(ReloadButton);
