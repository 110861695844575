import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation } from 'react-router-dom';
import { ProgramConstant } from '../redux/reducers';
import { API } from '../config/api';
import { nFormat } from '../libs/string';
import RTTable from '../libs/table';
import { fetchData } from '../libs/db';
import { Store } from '../store'

const Projects = ({ user, allgroups, allprojects }) => {

	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const [ items, setItems ] = useState([]);
	const [ list, setList ] = useState([]);
	const [ process, setProcess ] = useState(false);
	const [ err, setErr ] = useState("");
	const [ search, setSearch ] = useState("");
	
	useEffect(() => {
		dispatch({ type : ProgramConstant.PAGE, item : {
			label : t("Order"),
			caption : t("Manage orders information")
		}});
	},[])
	
	useEffect(() => {
		if(search){
			setList(makeItems(allprojects.filter(x => x.name.indexOf(search) > -1 || x.patientId.indexOf(search) > -1)));
		}else{
			setList(makeItems(allprojects));
		}
	},[search, allprojects])

	const makeItems = (showitems) => {
		return showitems.map(x => {
			const lab = allgroups.find(g => g._id === x.labId);
			const clinic = allgroups.find(g => g._id === x.ownerGroupId);
			return {...x, 
				labname : lab?.name, 
				clinicname : clinic?.name, 
				countryCode : lab?.countryCode
			};
		});
	}

	const headers = [
        { link : "labId", value : "labname", label : t("Lab"), css : "text-start", linkpath : "/group" },
        { value : "patientId", label : t("PID"), css : "text-start" },
        { value : "countryCode", label : t("Country"), css : "text-start", type : "COUNTRY" },
        { value : "ownerGroupId", label : t("Clinic"), css : "text-start", type : "CLINIC" },
        { value : "count", label : t("Files"), css : "text-end", type : "NUMBER" },
        { value : "size", label : t("Size"), css : "text-end", type : "SIZE" },
        { value : "isDel", label : t("Trash"), css : "text-center", type : "TOGGLE" },
        { value : "isArchive", label : t("Complete"), css : "text-center", type : "TOGGLE" },
        { value : "created", label : t("Created"), css : "text-center", type : "DATE" }
	]
	
  	return <div className="card">
		<div className="card-header">
			<div className="card-title">
				<div className="d-flex align-items-center position-relative my-1">
					<span className="svg-icon svg-icon-1 position-absolute ms-6">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
							<rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
							<path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path>
						</svg>
					</span>
					<input 
						type="text" 
						className="form-control form-control-solid w-250px ps-14" 
						placeholder="Search keyword" onChange={(e) => setSearch(e.target.value)} />
				</div>
			</div>
			<div className="card-toolbar">
				<span className="ms-4 text-gray-800">Total : {list && nFormat(list.length)}</span>
			</div>
		</div>
		<div className="card-body">
			<RTTable 
				headers={headers} 
				items={list} 
				loading={process} />
		</div>
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const allgroups =  state.ProgramReducer.allgroups;
	const allprojects =  state.ProgramReducer.allprojects;

	return { user, allgroups, allprojects };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(Projects);
