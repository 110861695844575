import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation } from 'react-router-dom';

import ContentTItle from './contenttitle';
import TopSearch from './topsearch';
import TopAlarm from './topalarm';
import ReloadButton from '../components/reload';

const Footer = ({ user, loginresult, page }) => {
    const domain = window.location.href.indexOf(".rayteams.com") > -1 ? "rayteams.com" : "raydevelop.com";
  	return (
		<div className="footer py-4 d-flex flex-lg-column">
			<div className="container-fluid d-flex flex-column flex-md-row flex-stack">
				<div className="text-dark order-2 order-md-1">
					<span className="text-gray-400 fw-bold me-1">Ray Co., Ltd.</span>
					{/*<a href="https://keenthemes.com" target="_blank" className="text-muted text-hover-primary fw-bold me-2 fs-6">Ray Co., Ltd.</a>*/}
				</div>
				<ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
					<li className="menu-item">
						<a href={"https://www." + domain + "/download"} target="_blank" className="menu-link px-2">RAYTeams Download</a>
					</li>
					<li className="menu-item">
						<a href={"https://www." + domain} target="_blank" className="menu-link px-2">RAYTeams</a>
					</li>
					<li className="menu-item">
						<a href="http://www.raymedical.com" target="_blank" className="menu-link px-2">Raymedical</a>
					</li>
				</ul>
			</div>
		</div>
  	);
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const loginresult =  state.AuthReducer.loginresult;
	const page =  state.ProgramReducer.page;

	return { user, loginresult, page };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(Footer);
