const initialState = { 
	expandleftmenu : [],
	allgroups : [],
	allusers : [],
	allprojects : [],
	allpartner : [],
	allclinics : [],
	partnertree : [],
	alldevices : [],
	myartner : {},
	page : {},
	custompage : { label : "", caption : "" },
	pc : {}
};

export const ProgramConstant = {
	ADDUSER : "PROGRAM_ADDUSER",
	ALLPROJECT : "PROGRAM_ALLPROJECT",
	PARTNERADD : "PROGRAM_PARTNERADD",
	ALLDEVICE : "PROGRAM_ALLDEVICE",
	UPDATEDEVICE : "PROGRAM_UPDATEDEVICE",
	EXPAND : "PROGRAM_LEFTMENU_EXPAND",
	ALLGROUPS : "PROGRAM_ALLGROUPS",
	GROUPUPDATE : "PROGRAM_GROUPUPDATE",
	PARTNERUPDATE : "PROGRAM_PARTNERUPDATE",
	ALLUSERS : "PROGRAM_ALLUSERS",
	GETPARTNER : "PROGRAM_GETPARTNER",
	GROUPADD : "PROGRAM_GROUPADD",
	GETCLINIC : "PROGRAM_GETCLINIC",
	GETUSER : "PROGRAM_GETUSER",
	PC : "PROGRAM_PC",
	PAGE : "PROGRAM_LEFTMENU_PAGE",
};

const getTree = (data, pid = "") => {

	function toTree(data, pid = "") {
		return data.reduce((r, e) => {
			if (e.parentId == pid) {
				const obj = { ...e };
				const children = toTree(data, e._id);
				if (children.length) obj.children = children;
				r.push(obj);
			}
			return r;
		}, [])
	}

	const result = toTree(data, pid)
	console.log(result);
	return result;
}

export const ProgramReducer = (state = initialState, action) => {
	switch (action.type) {
		case ProgramConstant.PC:
			return {...state, pc : action.pc };
		case ProgramConstant.ALLUSERS:
			return {...state, allusers : action.items };
		case ProgramConstant.ALLPROJECT:
			return {...state, allprojects : action.items };
		case ProgramConstant.UPDATEDEVICE:
			return {...state, alldevices : state.alldevices.map(x => x.sn === action.item.sn ? action.item : x) };
		case ProgramConstant.GROUPUPDATE:
			return {...state, allgroups: state.allgroups.map(x => x._id === action.group._id ? action.group : x) };

		case ProgramConstant.GETUSER:
			const existUser = state.allusers.find(x => x._id === action.user._id);
			if(existUser){
				return {...state, allusers: state.allusers.map(x => x._id === action.user._id ? {...x, ...action.user} : x) };
			}else{
				return {...state, allusers: [...state.allusers, action.user] };
			}

		case ProgramConstant.GETCLINIC:
			const existClinic = state.allclinics.find(x => x._id === action.clinic._id);
			if(existClinic){
				return {...state, allclinics: state.allclinics.map(x => x._id === action.clinic._id ? action.clinic : x) };
			}else{
				return {...state, allclinics: [...state.allclinics, action.clinic] };
			}

		case ProgramConstant.ALLDEVICE:
			return {...state, alldevices : action.items };
		case ProgramConstant.ADDUSER:
			return {...state, allusers : [...state.allusers, action.user] };
		case ProgramConstant.GROUPADD:
			return {...state, allgroups: [...state.allgroups, action.group] };
		case ProgramConstant.ALLGROUPS:
			return {...state, allgroups : action.items };
		case ProgramConstant.GETPARTNER:
			const allp = action.items;
			return {...state, allpartner : allp, partnertree : getTree(allp, action.my._id), mypartner : action.my };
		case ProgramConstant.PARTNERUPDATE:
			const allpup = state.allpartner.map(x => x._id === action.partner._id ? action.partner : x);
			return {...state, allpartner : allpup, partnertree : getTree(allpup, state.mypartner._id) };
		case ProgramConstant.PARTNERADD:
			const allpadd = [...state.allpartner, action.partner];
			return {...state, allpartner : allpadd, partnertree : getTree(allpadd, state.mypartner._id) };
		case ProgramConstant.PAGE:
			return {...state, page : action.item };
		case ProgramConstant.EXPAND:
			return {...state, 
				expandleftmenu : state.expandleftmenu.includes(action.item) ? 
					state.expandleftmenu.filter(x => x !== action.item)  : 
					[state.expandleftmenu, action.item]
			};
		default:
			return state;
	}
};
