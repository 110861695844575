import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation } from 'react-router-dom';

const Logo = () => {

  	return (
		<div className="aside-logo flex-column-auto px-9 mb-9 mb-lg-17 mx-auto">
			<a href="/">
				<img alt="Logo" src="assets/media/logos/logo-1.svg" className="h-30px logo" />
			</a>
		</div>
  	);
}

const mapState = (state) => {
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(Logo);

