import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation } from 'react-router-dom';

const LogUser = ({ user, mypartner }) => {

  	return (
		<div className="aside-user mb-5 mb-lg-10" id="kt_aside_user">
			<div className="d-flex align-items-center flex-column">
				{mypartner?.logo && <div className="mb-4 text-center">
					<img src={mypartner?.logo} alt="" className='mh-150px mw-250px' />
				</div>}
				<div className="text-center">
					<Link to="/Me" className="text-gray-900 text-hover-primary fs-4 fw-boldest">{user?.name}</Link>
					<span className="text-gray-600 fw-bold d-block fs-7 mb-1">{user?.email}</span>
					<div className="badge badge-info fs-5">{mypartner?.name}</div>
				</div>
			</div>
		</div>
  	);
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const mypartner =  state.ProgramReducer.mypartner;

	return { user, mypartner };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(LogUser);

