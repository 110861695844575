import moment from 'moment';
import { useEffect, useState } from 'react';
import { BsCheckCircle, BsCircle } from 'react-icons/bs';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { history } from '../history';
import RTPagination from './pagination';
import { COUNTRIES } from '../config/country';
import User from '../components/user';
import { RTPanelNoData } from '../libs/panel';
import Clinic from '../components/clinic';
import { RTSize, RTGroup, RTPartner } from '../libs/string';

const RTTable = ({ headers, items, filters, linkprefix, callback, noheader, notableheader, nocard, rowkey,
	fillHeight = false, simple = false, showall = false, rowComponent : Component,
	codes, title, options = {}, holePageWhenUpdate = false, tableWrapCss, callbackClickRow }) => {

	const DEFAULT = {
		ITEMPERPAGE: 15
	}

	const [filterCols, setFilterCols] = useState(options.filterCols || []);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemPerPage, setItemPerPage] = useState(DEFAULT.ITEMPERPAGE);
	const [allList, setAllList] = useState([]);
	const [nowList, setNowList] = useState([]);

	const getVal = (item, header) => {
		var ret = item[header.value];
		if (header.type === "DATE") {
			ret = ret ? moment.unix(ret / 1000).format("yyyy-MM-DD HH:mm:ss") : "";
		} else if (header.type === 'DATE_TIME_TWO_LINE') {
			const date = moment.unix(ret / 1000);
			ret = <>
				<div>{date.format('yyyy.MM.DD')}</div>
				<small className="text-muted">{date.format('HH:mm:ss')}</small>
			</>
		} else if (header.type === "DATE2") {
			ret = ret ? moment(ret).format("yyyy-MM-DD HH:mm:ss") : "";
		} else if (header.type === "DATESHORT") {
			ret = ret ? moment(ret).format("yyyy-MM-DD") : "";
		} else if (header.type === "TIMESPAN") {
			ret = ret ? moment.unix(ret / 1000).fromNow() : "";
		} else if (header.type === "WORKFLOW") {
			ret = <>
				{wfdata.find(x => x._id === ret)?.title || ret}
			</>
		} else if (header.type === "USER") {
			ret = <>
				<User _id={ret}  region={item?.region}/>
			</>
		} else if (header.type === "CLINIC") {
			ret = <>
				<Clinic cid={ret} />
			</>
		} else if (header.type === "LABS") {
			ret = <>
				{ret.map(x => <RTGroup gid={x} />)}
			</>
		} else if (header.type === "USERBYID") {
			ret = <>
			</>
		} else if (header.type === "USERBYIDFORBOARD") {
			ret = <>
			</>
		} else if (header.type === "COUNT") {
			ret = <>
				{item.comments.length}
			</>
		} else if (header.type === "CUR") {
			ret = <>
				{header.curtype && (item[header.curtype] + ' ' || "")}
				{ret.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
			</>
		} else if (header.type === "CNT") {
			ret = <>
				{ret ? ret.length : 0}
			</>
		} else if (header.type === "TFVALUE") {
			ret = <>
				{ret && <BsCheckCircle size={24} />}
				{!ret && <BsCircle size={24} />}
			</>
		} else if (header.type === "USER") {
			ret = <>
			</>
		} else if (header.type === "USERMODAL") {
			ret = <>
			</>;
		} else if (header.type === "ORDERPHOTO") {
			if (!ret) {
				const p = patients.find(x => x.patientId === item?.patient?.patientId);
				ret = p ? p.photo : ret;
				ret = <>
					<div className="symbol symbol-50px">
					</div>
				</>;
			} else {
				ret = <>
					<div className="symbol symbol-50px">
					</div>
				</>;
			}
		} else if (header.type === "LANGUAGE") {
			ret = <>
				{/*LANGUAGE.find(x => x.code === ret)?.label */}
			</>;
		} else if (header.type === "AGE") {
			ret = <>
				{ret} ~ {parseInt(ret) + 9}
			</>;
		} else if (header.type === "BADGE") {
			ret = <>
				<span className='badge badge-success'>{ret}</span>
			</>;
		} else if (header.type === "SIZE") {
			ret = <RTSize bytes={ret} />;
		} else if (header.type === "PARTNER") {
			ret = <RTPartner pid={ret} />
		} else if (header.type === "GROUP") {
			ret = <RTGroup gid={ret} />
		} else if (header.type === 'TOGGLE') {
			ret = <>{ret ? "Y" : "N"}</>
		//} else if (header.type === 'EMAIL') {
		//	ret = <MLEmail email={ret} emailVerified={item?.emailVerified} />
		} else if (header.type === 'COUNTRY') {
			if (item[header.value]) {
				ret = <span className=''>{COUNTRIES.find(x => x.countryCode === ret)?.name}</span>
			} else {
				ret = <></>
			}
		} else if (header.type === 'arrayValue') {
			var splitItem = header.value.split('.');
			ret = <>{item[splitItem[0]]?.[splitItem[1]]}</>
		}
		if (header.link) {
			if(Array.isArray(header.link)){
				const addPath = header.link.map(x => item[x]).join("/");
				ret = <Link to={(linkprefix ? linkprefix : "") + header.linkpath + '/' + addPath}>{ret}</Link>
			}else{
				if (header.linkappend) {
					ret = <Link to={(linkprefix ? linkprefix : "") + header.linkappend + item[header.link]}>{ret}</Link>
				} else if (header.linkMultivalue) {
					const sItem = ret.split(",");
					const rItems = [];
					for (const c of sItem) {
						rItems.push(<Link to={(linkprefix ? linkprefix : "") + header.linkpath + '/' + c.trim()}>{c + ", "}</Link>)
					}
					ret = <>
						{rItems}
					</>
				} else {
					ret = <> <Link to={(linkprefix ? linkprefix : "") + header.linkpath + '/' + item[header.link]}>{ret}</Link> </>
				}
			}
		} else if (header.callback && callback) {
			ret = <Link to="#" onClick={() => callback(item)}>{ret}</Link>
		}
		return ret;
	}

	const getRow = (item, rowindex) => {
		var ret = [];
		headers.filter(x => !x.hidden).filter(x => simple ? !x.FULL : true).map((x, idx) => ret.push(<td key={'td-' + rowindex + "-" + idx} className={x.css || ''}>{getVal(item, x)}</td>));
		return ret;
	}

	useEffect(() => {
		if (options.filterCols)
			setFilterCols(options.filterCols || []);
	}, [options]);

	useEffect(() => {
		var list = filterItems(items);
		setAllList(list);
	}, [items]);

	useEffect(() => {
		!holePageWhenUpdate && setCurrentPage(1);
		setNowList(filteredData(allList));
	}, [allList, filterCols]);

	const paginate = (list) => {
		return showall ? list : list.slice((currentPage - 1) * itemPerPage, currentPage * itemPerPage);
	};

	const filterItems = () => {
		if (filters) {
			var tmp = [];
			Object.keys(filters).forEach(x => {
				var it = items.filter(y => {
					if (Array.isArray(filters[x])) {
						return filters[x].includes(y[x]);
					} else {
						return y[x] === filters[x];
					}
				});
				it.forEach(m => {
					if (tmp.filter(f => f._id === m._id).length === 0)
						tmp.push(m);
				})
			})
			return tmp;
		}
		return items;
	}

	const getColGroup = (f) => {
		return [
			...new Set(
				allList.map((item) => {
					return item[f.col];
				}, [])
			),
		].sort((a, b) => a < b ? -1 : 1);
	};

	const handleFilter = (e, idx) => {
		const newFilter = [...filterCols];
		if (e.value === "true" || e.value === "false") {
			newFilter[idx].val = e.value === "true" ? true : false;
		} else {
			newFilter[idx].val = e.value;
		}
		setFilterCols(newFilter);
	};

	const filteredData = (originData) => {
		var nData = [];
		var nFilter = [...filterCols.filter(x => x.val !== "ALL")];
		if (nFilter.length > 0) {
			nData = originData.filter(x => {
				const filterResult = [];
				for (var i = 0; i < nFilter.length; i++) {
					filterResult.push(x[nFilter[i].col] === nFilter[i].val)
				}
				return filterResult.filter(Boolean).length === nFilter.length;
			})
			return nData;
		} else {
			return originData;
		}
	};

	const getValByCode = (val, nullVal = "") => {
		if (Array.isArray(val)) {
			const ns = codes.filter(x => val.includes(x.code));
			return ns.length > 0 ? (ns.map(m => m.title).join(", ") || (nullVal || val)) : (nullVal || val);
		} else {
			const ns = codes.filter(x => val === x.code);
			return ns.length > 0 ? (ns[0].title || (nullVal || val)) : (nullVal || val);
		}
	}

	const getValByPublished = (val, nullVal = "") => {
		return val ? "PUBLISHED" : "DRAFT";
	}

	const getOptions = (item, x) => {
		var ret = x;
		if (item.type === "CODE") { ret = getValByCode(x) }
		if (item.type === "PUBLISHED") { ret = getValByPublished(x) }
		return {
			value: x,
			label: ret
		}
	}

	const getOptionList = (item, options) => {
		var ret = [{ value: "ALL", label: "ALL" }];
		return ret.concat(options.map(x => getOptions(item, x)));
	}

	return <>
		{nowList.length > 0 && <table className='table table-row-dashed table-row-gray-300'>
			{!notableheader && <thead>
				<tr className='text-start text-muted fw-bolder text-uppercase gs-0'>{headers.filter(x => !x.hidden)
						.filter(x => simple ? !x.FULL : true).map((x, idx) =>
					<th key={"header-" + idx} className={x.css || ''} style={x.style && x.style}>{x.label}</th>)}</tr>
			</thead>}
			<tbody>
                {Component && paginate(nowList).map((x, idx) => <Component row={x} rowIndex={idx} headers={headers} key={'tr-' + (rowkey ? x[rowkey] : idx)}/>)}
				{!Component && paginate(nowList).map((x, idx) => <tr key={'tr-' + (rowkey ? x[rowkey] : idx)} onClick={() => callbackClickRow && callbackClickRow(x)}>
                    {getRow(x, idx)}
                </tr>)}
			</tbody>
		</table>}
		{nowList.length === 0 && <RTPanelNoData />}
		{nowList.length > itemPerPage && <RTPagination
				pageSize={itemPerPage}
				totalItems={nowList.length}
				curPage={options?.useapi ? 1 : currentPage}
				paginate={setCurrentPage} />}
	</>
}

const mapState = (state) => {
};

const mapDispatch = dispatch => ({
});

export default connect(null, mapDispatch)(RTTable);
