import { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation } from 'react-router-dom';

const ContentTitle = ({ user, page, pagecustom }) => {

	const [ custom, setCustom ] = useState({
		label : "",
		caption : ""
	});

	useEffect(() => {
	}, [page]);

	const makeTop = () => {
	}

  	return (
		<>
			<div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-2 pb-5 pb-lg-0 pt-7 pt-lg-0">
				<h1 className="d-flex flex-column text-dark fw-bolder my-0 fs-1">
					{page?.label}
					<small className="text-muted fs-6 fw-bold pt-1">{page?.caption}</small>
				</h1>
			</div>
			<div className="d-flex d-lg-none align-items-center ms-n3 me-2">
				<div className="btn btn-icon btn-active-icon-primary">
					<span className="svg-icon svg-icon-1 mt-1">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
							<path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black"></path>
							<path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black"></path>
						</svg>
					</span>
				</div>
				<a href="../../demo15/dist/index.html" className="d-flex align-items-center">
					<img alt="Logo" src="/media/logos/logo-2.svg" className="h-30px" />
				</a>
			</div>
		</>
  	);
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const page =  state.ProgramReducer.page;
	const pagecustom =  state.ProgramReducer.pagecustom;
	return { user, page, pagecustom };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(ContentTitle);

