export default {
	"Save Path":"Save Path",
	"Manage your organization" : "Manage your organization",
	"Add sub-partner" : "Add sub-partner",
	"Partners" : "Partners",
	"Information" : "Information",
	"Member(s)" : "Member(s)",
	"Device" : "Device",
	"Acqusitions" : "Acqusitions",
	"DeviceName" : "DeviceName",
	"Partner" : "Partner",
	"Lab(s)" : "Lab(s)",
	"Lab" : "Lab",
	"Active" : "Active",
	"Activated" : "Activated",
	"Assigned" : "Assigned",
	"Search keyword" : "Search keyword",
	"Clinic" : "Clinic",
	"Order" : "Order",
	"Order Name" : "Order Name",
	"Manage orders information" : "Manage orders information",
	"Manage lab information" : "Manage lab information",
	"Name" : "Name",
	"Address" : "Address",
	"Country" : "Country",
	"Add a lab" : "Add a lab",
	"Files" : "Files",
	"Size" : "Size",
	"Trash" : "Trash",
	"Complete" : "Complete",
	"Created" : "Created",
	"PID" : "PID",
	"Cancel" : "Cancel",
	"Update" : "Update",
	"Edit" : "Edit",
	"Enter partner name" : "Enter partner name",
	"Enabled" : "Enabled",
	"Disabled" : "Disabled",
	"Sharing" : "Sharing",
	"ID" : "ID",
	"Last Login" : "Last Login",
	"Partner Manager" : "Partner Manager",
	"All" : "All",
}
