import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation } from 'react-router-dom';
import { ProgramConstant } from '../redux/reducers';
import { API } from '../config/api';
import RTTable from '../libs/table';
import { nFormat } from '../libs/string';
import { fetchData } from '../libs/db';
import DeviceRow from '../components/devicerow';
import { Store } from '../store'

const Devices = ({ user, allgroups, alldevices }) => {

	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const [ items, setItems ] = useState([]);
	const [ list, setList ] = useState([]);
	const [ process, setProcess ] = useState(false);
	const [ err, setErr ] = useState("");
	const [ search, setSearch ] = useState("");
	
	useEffect(() => {
		//getitems();
		dispatch({ type : ProgramConstant.PAGE, item : {
			label : t("Devices"),
			caption : t("Manage devices")
		}});
	},[])
	
	useEffect(() => {
		if(search){
			setList(makeItems(alldevices.filter(x => x.name.indexOf(search) > -1 || x.patientId.indexOf(search) > -1)));
		}else{
			setList(makeItems(alldevices));
		}
	},[search, alldevices])

	const makeItems = (showitems) => {
		return showitems.map(x => {
			const lab = allgroups.find(g => g._id === x.labId);
			const clinic = allgroups.find(g => g._id === x.ownerGroupId);
			return {...x, 
				labname : lab?.name, 
				clinicname : clinic?.name, 
				countryCode : clinic?.countryCode
			};
		});
	}

	const headers = [
		{ link : "sn", value : "sn", label : "SN", css : "text-start", linkpath : "/device" },
        { value : "DeviceName", label : t("Device Type"), css : "text-start" },
        { value : "ownerId", label : t("Clinic"), css : "text-start", type : "CLINIC" },
        //{ value : "partnerId", label : t("Partner"), css : "text-start", type : "PARTNER" },
        { value : "labs", label : t("Lab(s)"), css : "text-start", type : "LABS" },
        //{ value : "isactive", label : t("Active"), css : "text-center", type : "TF" },
        { value : "issharable", label : t("Sharable"), css : "text-center", type : "TF" },
        { value : "registered", label : t("Assigned"), css : "text-center", type : "DATESHORT" },
        { label : "", css : "text-center" },
	]
	
  	return <div className="card">
		<div className="card-header">
			<div className="card-title">
				<div className="d-flex align-items-center position-relative my-1">
					<span className="svg-icon svg-icon-1 position-absolute ms-6">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
							<rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
							<path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path>
						</svg>
					</span>
					<input 
						type="text" 
						className="form-control form-control-solid w-250px ps-14" 
						placeholder={t("Search keyword")} onChange={(e) => setSearch(e.target.value)} />
				</div>
			</div>
			<div className="card-toolbar">
				<span className="ms-4 text-gray-800">Total : {list && nFormat(list.length)}</span>
			</div>
		</div>
		<div className="card-body">
			<RTTable 
				rowkey={"sn"}
				headers={headers} 
				items={list} 
				rowComponent={DeviceRow}
				loading={process} />
		</div>
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const allgroups =  state.ProgramReducer.allgroups;
	const alldevices =  state.ProgramReducer.alldevices;

	return { user, allgroups, alldevices };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(Devices);
