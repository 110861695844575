import { combineReducers } from "redux";
import { AuthReducer, AuthConstant } from "./AuthRed";
import { ProgramReducer, ProgramConstant } from "./ProgramRed";

const appReducer = combineReducers({
    AuthReducer,
    ProgramReducer
});

const RootReducer = (state, action) => {
    if (action.type === AuthConstant.LOGOUT) {
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
};

export default RootReducer;
export {
    AuthConstant,
    ProgramConstant
};
