import i18n from "i18next";
import i18next from 'i18next';
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import en from '../lang/en';
import ko from '../lang/ko';
import jp from '../lang/jp';

const resources = {
    en: { translation: en },
    jp: { translation: jp },
    ko: { translation: ko },
};
i18n                                        
    .use(detector)
    .use(initReactI18next)
    .init({
        resources,
        lng: "en",
        keySeparator: ".",
        interpolation: {
          escapeValue: false
        }
    });

export default i18n;
