import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation, useParams } from 'react-router-dom';
import { ProgramConstant } from '../redux/reducers';
import { COUNTRIES } from '../config/country';
import { COLORS } from '../config/color';
import { API } from '../config/api';
import RTTable from '../libs/table';
import { RTButton } from '../libs/button';
import { RTGroup } from '../libs/string';
import { idData, fetchData } from '../libs/db';
import CompGroupList from '../components/grouplist';
import CompProjectList from '../components/projectlist';
import { Store } from '../store'

const Lab = ({ user, allgroups, _id, card = false }) => {

	const dispatch = useDispatch();

	const [t] = useTranslation(['translation']);
	const [ info, setInfo ] = useState({});
	const [ process, setProcess ] = useState(false);

	useEffect(() => {
		if(_id){
			getitem();
		}
	},[_id]);

	const getitem = async () => {
		const lab = allgroups.find(x => x._id === _id);
		if(lab){
			setInfo(lab);
			return;
		}
	}
	
  	return <>
		{!card && <>
			<div>
				<a onClick={() => history.push("/lab/" + info._id)} className="cursor-pointer me-2">{info.name}</a>
				<span className='text-gray-600'>{COUNTRIES.find(x => x.countryCode === info.countryCode)?.name}</span>
			</div>
		</>}
		{card && <></>}
	</>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const allgroups =  state.ProgramReducer.allgroups;
	return { user, allgroups };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(Lab);
