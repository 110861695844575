import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation, useParams } from 'react-router-dom';
import { FaSave, FaEdit, FaWindowClose, FaCheckCircle, FaStopCircle } from 'react-icons/fa';
import { ProgramConstant } from '../redux/reducers';
import { COUNTRIES } from '../config/country';
import Clinic from '../components/clinic';
import { API } from '../config/api';
import { RTPartnerSelect, RTSelect, RTSelectMulti } from '../libs/input';
import { COLORS } from '../config/color';
import RTTable from '../libs/table';
import { RTSize, RTGroup, RTPartner } from '../libs/string';
import { RTButton } from '../libs/button';
import { idData, fetchData, updateData } from '../libs/db';
import { Store } from '../store'

const CompDeviceInfo = ({ user, title, device, allusers, allgroups }) => {

	const dispatch = useDispatch();

	const [t] = useTranslation(['translation']);
	const [ items, setItems ] = useState([]);
	const [ edit, setEdit ] = useState(false);
	const [ data, setData ] = useState({});
	const [ process, setProcess ] = useState(false);

	useEffect(() => {
		if(device){
			setData({
				isactive : device.isactive,
				labs : device.labs,
				partnerId : device.partnerId
			});
		}
	}, [device]);

	const update = async () => {
		setProcess(true);

		const ret = await updateData("rayteams-device", { sn : device.sn, sk : "info" }, data);
		dispatch({ 
			type : ProgramConstant.UPDATEDEVICE, 
			item : ret
		});

		setProcess(false);

		setEdit(false);
	}

	const getLabs = () => {
		const gItems = [];
		device.labs?.map((x, idx) => {
			gItems.push(<RTGroup gid={x} readonly={edit} key={"lab-" + x} />);
			if(idx < device.labs.length - 1)
				gItems.push(<span key={"sp-" + x}>, </span>);
		});
        if(gItems.length === 0 )
            gItems.push(<span className="font-italic text-secondary">Not assigned</span>);
		return gItems;
	}

	return <div className="card mb-5 shadow">
		<div className="card-header">
			<div className="card-title">{title}</div>
			<div className="card-toolbar">
				{edit && <>
					<RTButton options={{
						label : t("Cancel"),
						color : COLORS.SECONDARY,
						size : "sm",
						action : () => setEdit(false)
					}} css="me-2" />
					<RTButton options={{
						label : t("Update"),
						size : "sm",
						action : () => update()
					}} />
				</>}
				{!edit && <RTButton options={{
					label : t("Edit"),
					size : "sm",
					action : () => setEdit(true)
				}} />}
			</div>
		</div>
		{device && <div className="card-body">
			<div className="mb-5">
				<label className="form-label fs-7 text-gray-900">{t("Partner")}</label>
				<div className="text-gray-600">
					{!edit && <RTPartner pid={device.partnerId} readonly={true} />}
					{edit && <RTPartnerSelect options={{
						name : "partnerId",
						value : data.partnerId
						}} handleChanged={(e) => setData({...data, partnerId : e.target.value})} />}
				</div>
			</div>
			<div className="mb-5">
				<label className="form-label fs-7 text-gray-900">{t("Lab")}</label>
				<div className="text-gray-600">
					{!edit && getLabs()}
					{edit && <RTSelectMulti options={{
						name : "labs",
						value : data.labs,
						list : allgroups.filter(x => x.partnerId === data.partnerId).map(x => ({ value : x._id, label : x.name }))
						}} handleChanged={(e) => setData({...data, labs : e.target.value})} />}
				</div>
			</div>
			<div className="mb-5">
				<label className="form-label fs-7 text-gray-900">{t("Sharing")}</label>
				<div className="text-gray-600">
					{!edit && (data.isactive ? <>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-2h">
                            <rect opacity="1" x="2" y="2" width="20" height="20" rx="10" fill="#5014d0"></rect>
                            <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="#fff"></path>
                        </svg> {t("Enabled")}
                        </> : <>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-2h">
                        <rect opacity="0.1" x="2" y="2" width="20" height="20" rx="10" fill="#000"></rect>
                        <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="#b5b5b5"></path>
                        </svg> {t("Disabled")}</>)}
					{edit && <div className="form-check form-switch form-check-custom form-check-solid">
						<input className="form-check-input" 
							type="checkbox" 
							value={data.isactive} 
							checked={data.isactive ? "checked" : ""} 
							onChange={() => setData({...data, isactive : !data.isactive})}/>
						<label className="form-check-label fs-6 text-gray-800">
						</label>
					</div>}
				</div>
			</div>
			<div className="mb-5">
				<label className="form-label fs-7 text-gray-900">{t("Clinic")}</label>
				<div className="text-gray-600">
					<Clinic cid={device.ownerId} />
				</div>
			</div>
			<div className="mb-5">
				<label className="form-label fs-7 text-gray-900">{t("Created")}</label>
				<div className="text-gray-600">
					{moment(device.created).format("LL")}
				</div>
			</div>
			<div className="mb-5">
				<label className="form-label fs-7 text-gray-900">{t("Activated")}</label>
				<div className="text-gray-600">
					{moment(device.activated).format("LL")}
				</div>
			</div>
		</div>}
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const allusers =  state.ProgramReducer.allusers;
	const allgroups =  state.ProgramReducer.allgroups;

	return { user, allusers, allgroups };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(CompDeviceInfo);
