import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const RTPagination = ({ totalItems, curPage, pageSize, paginate }) => {
    const [t] = useTranslation(['translation']);
    const [currentPage, setCurrentPage] = useState(curPage || 1);
    const [pgSize, setPgSize] = useState(pageSize || 10);

    const totalPages = Math.ceil(totalItems / pgSize);

    var startPage, endPage;
    if (totalPages <= 10) {
        startPage = 1;
        endPage = totalPages;
    } else {
        if (currentPage <= 6) {
            startPage = 1;
            endPage = 10;
        } else if (currentPage + 4 >= totalPages) {
            startPage = totalPages - 9;
            endPage = totalPages;
        } else {
            startPage = currentPage - 5;
            endPage = currentPage + 4;
        }
    }

    const movePage = (pg) => {
        setCurrentPage(pg);
        paginate(pg);
    }

    useEffect(() => {
        setCurrentPage(curPage);
    }, [curPage])

    const startIndex = (currentPage - 1) * pgSize;
    const endIndex = Math.min(startIndex + pgSize - 1, totalItems - 1);
    const pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

    return (
        <ul className="pagination my-10">
            <li className={currentPage === 1 ? 'page-item p-l-0 disabled' : 'page-item p-l-0'}>
                <span className="raycur page-link" onClick={() => movePage(1)}>{t('First')}</span>
            </li>
            <li className={currentPage === 1 ? 'page-item p-l-0 disabled' : 'page-item p-l-0'}>
                <span className="raycur page-link" onClick={() => movePage(currentPage - 1)}>{t('Previous')}</span>
            </li>
            {pages.map((page, index) =>
                <li key={"p-" + index} className={currentPage === page ? 'page-item p-l-0 active' : 'page-item p-l-0'}>
                    <span className="raycur page-link" onClick={() => movePage(page)}>{page}</span>
                </li>
            )}
            <li className={currentPage === totalPages ? 'page-item p-l-0 disabled' : 'page-item p-l-0'}>
                <span className="raycur page-link" onClick={() => movePage(currentPage + 1)}>{t('Next')}</span>
            </li>
            <li className={currentPage === totalPages ? 'page-item p-l-0 disabled' : 'page-item p-l-0'}>
                <span className="raycur page-link" onClick={() => movePage(totalPages)}>{t('Last')}</span>
            </li>
        </ul>
    );
};

export default RTPagination;
