import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation } from 'react-router-dom';
import { MENU } from '../config/menu';
import LeftMenuItem from './leftmenuitem';

const LeftMenu = ({ user }) => {

  	return (
		<div className="aside-menu flex-column-fluid ps-3 ps-lg-5 pe-1 mb-9">
			<div className="w-100 hover-scroll-overlay-y pe-2 me-2">
				<div className="menu menu-column menu-rounded fw-bold">

					{MENU.map(x => <LeftMenuItem item={x} key={x.key} />)}

				</div>
			</div>
		</div>
  	);
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;

	return { user };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(LeftMenu);

