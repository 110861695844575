import axios from 'axios';
import { useEffect, useState } from 'react';
import { history } from '../history';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation } from 'react-router-dom';
import { ProgramConstant } from '../redux/reducers';
import { API } from '../config/api';
import RTTable from '../libs/table';
import { nFormat } from '../libs/string';
import { fetchData } from '../libs/db';
import DeviceRow from '../components/devicerow';
import { Store } from '../store'

const Orders = ({ user, allgroups, alldevices }) => {

	const dispatch = useDispatch();
	const [ items, setItems ] = useState([]);
	const [ list, setList ] = useState([]);
	const [ process, setProcess ] = useState(false);
	const [ err, setErr ] = useState("");
	const [ search, setSearch ] = useState("");
	
	useEffect(() => {
	},[])
	
	useEffect(() => {
	},[search, alldevices])

	const makeItems = (showitems) => {
	}

	const headers = [
		{ link : "_id", value : "_id", label : "Order No.", css : "text-start" },
        { value : "partnerId", label : "Partner", css : "text-start", type : "PARTNER" },
        { value : "labs", label : "Lab", css : "text-start" },
        { value : "status", label : "Status", css : "text-center" },
        { value : "created", label : "Created", css : "text-center", type : "DATESHORT" },
        { value : "updated", label : "Updated", css : "text-center", type : "DATESHORT" },
        { label : "", css : "text-center" },
	]
	
  	return <div className="card">
		<div className="card-header">
			<div className="card-title">
				<div className="d-flex align-items-center position-relative my-1">
					<span className="svg-icon svg-icon-1 position-absolute ms-6">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
							<rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
							<path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path>
						</svg>
					</span>
					<input 
						type="text" 
						className="form-control form-control-solid w-250px ps-14" 
						placeholder="Search keyword" onChange={(e) => setSearch(e.target.value)} />
				</div>
			</div>
			<div className="card-toolbar">
				<span className="ms-4 text-gray-800">Total : {list && nFormat(list.length)}</span>
			</div>
		</div>
		<div className="card-body">
			<RTTable 
				headers={headers} 
				items={list} 
				loading={process} />
		</div>
	</div>
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const allgroups =  state.ProgramReducer.allgroups;
	const alldevices =  state.ProgramReducer.alldevices;

	return { user, allgroups, alldevices };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(Orders);
