
import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, Route, Router, Switch, useLocation } from 'react-router-dom';
import Logo from './logo';
import LogUser from './loguser';
import LeftMenu from './leftmenu';
import LeftBottom from './leftbottom';

const Left = ({ user }) => {

  	return (
		<div className="aside pt-7 pb-4 pb-lg-7 pt-lg-17">

			{/* <Logo /> */}

			<LogUser />

			<LeftMenu />

			<LeftBottom />

		</div>
  	);
}

const mapState = (state) => {

	const user =  state.AuthReducer.user;

	return { user };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(Left);

